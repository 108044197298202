import {FunctionComponent, useState} from "react";
import styled from "styled-components";

import dot from './dot.svg';
import {RoadItem} from "./RoadItem";
import {BrowserView, MobileView} from "react-device-detect";

const RoadWrapperDiv = styled.div`
    position: absolute;
    width: 370px;
    height: 188px;
    right: 3px;
    bottom: 50px;

    grid-gap: 1px;
    display: grid;
    font-family: Inter,sans-serif;
    grid-template-rows: 15px calc(100% - 18px);
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
`;
const MobileRoadWrapperDiv = styled.div`
    margin-left: 10px;
    width: calc(100% - 20px);
    
    grid-gap: 1px;
    display: grid;
    font-family: Inter,sans-serif;
    grid-template-rows: 15px calc(100% - 18px);
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
`;

const RoadBallHeaderDiv = styled.div`
    background: linear-gradient(180deg,rgba(72,130,154,.9) -37.5%,rgba(49,70,89,.9));
    border-radius: 6px 6px 2px 2px;
    font-weight: 500;
    height: 100%;
    position: relative;
    width: 100%;
`;
const RoadBallHeaderItemDiv = styled.div<{ selected?: boolean }>`
    left: 0;
    position: absolute;
    top: 0;
    //transition-delay: .3s;
    transition-duration: .5s;
    transition-property: opacity,top,left,width,height;
    height: 100%;
    width: calc(50% - 1%);

    ${props => props.selected === true ? `
        width: 100%;
    ` : props.selected === false ? `
        opacity: 0;
        transition-delay: 0;
    ` : ''}
`;
const RoadBallHeaderItemTextDiv = styled.span<{ selected?: boolean }>`
    font-weight: 300;
    height: 100%;
    text-shadow: 0.5px 0.5px 0 rgba(0,0,0,.5);
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    color: white;
    font-size: 10px;

    ${props => props.selected === true ? `
        opacity: 0;
    ` : props.selected === false ? `
        opacity: 0;    
    ` : ''}
`;
const RoadBallHeaderSeparatorWrapperDiv = styled.div`
    height: 100%;
    left: calc(50.25% - 1%/2);
    width: .25%;
    position: absolute;
    transition: opacity .5s .3s;
`;
const RoadBallHeaderSeparatorDiv = styled.div<{ selected?: boolean }>`
    background: linear-gradient(193.24deg,rgba(209,229,239,.6) 9.53%,rgba(33,47,56,.6) 90.5%);
    height: 100%;
    transform: translateX(-50%);
    width: 100%;

    ${props => props.selected === true ? `
        opacity: 0;
    ` : props.selected === false ? `
        opacity: 0;
        transition-delay: 0;    
    ` : ''}
`;

const RoadBallContentWrapperDiv = styled.div`
    height: 100%;
    position: relative;
    width: 100%;
`;
const RoadBallContentDiv = styled.div`
    height: 49%;
    width: 100%;
    left: 0;
    position: absolute;
    top: 0;
    //transition-delay: .3s;
    transition-duration: .5s;
    transition-property: opacity,top,left,width,height;
`;
const RoadBallGroupDiv = styled.div<{ selected?: boolean }>`
    grid-gap: 3px;
    display: grid;
    grid-template-rows: 15px calc(100% - 17px);
    height: 100%;
    position: relative;
    width: 100%;
    //transition-delay: .3s;
    transition-duration: 500ms;

    ${({ selected }) => selected === true ? `
        height: 100%;
    ` : selected === false ? `
        opacity: 0;
        transition-delay: 0;
    ` : ''}
`;
const RoadBallGroupHeaderDiv = styled.div`
    background: rgba(86,113,124,.4);
    border-radius: 0.3em;
    font-weight: 300;
    font-size: 10px;
    text-align: center;
    line-height: 15px;
    color: white;
`;
const RoadBallGroupContentWrapperDiv = styled.div`
    height: 100%;
    position: relative;
    width: 100%;
`;
const RoadBallGroupItemDiv = styled.div<{ selected?: boolean }>`
    height: 100%;
    width: 49%;
    left: 0;
    position: absolute;
    top: 0;
    //transition-delay: .3s;
    transition-duration: .5s;
    transition-property: opacity,top,left,width,height;
  
    ${({ selected }) => selected === true ? `
        width: 100%;
    ` : selected === false ? `
        opacity: 0;
        transition-delay: 0;
    ` : ''}
`;
const RoadContentWrapperDiv = styled.div<{ selected?: boolean }>`
    justify-content: flex-start;
    left: 0;
    top: 0;
    transform-origin: top left;
    align-items: flex-start;
    cursor: zoom-in;
    display: flex;
    height: 200%;
    overflow: visible;
    pointer-events: all;
    position: absolute;
    transform: scale3d(.5,.5,.5);
    transition: transform .5s,width .5s,height .5s;
    width: 200%;
  
    ${props => props.selected === true ? `
        cursor: zoom-out;
        height: 100%;
        transform: scaleX(1.02);
        transition: transform .5s,width .5s,height .5s;
        width: 100%;
    ` : props.selected === false ? `
        
    ` : ''}
`;
const RoadContentDiv = styled.div`
    background-size: 24px;
    width: 370px;
    height: 130px;
    background-image: url(${dot});
    background-repeat: repeat;
`;
const MobileRoadContentDiv = styled.div`
    background-size: 24px;
    width: 400px;
    height: 110px;
    background-image: url(${dot});
    background-repeat: repeat;
`;


export const Road: FunctionComponent<{
    history: {
        ballValues: number[];
        gameId: string;
        gameNumber: string;
        roundId: number;
        roundIds: number[];
    }[];
    small?: boolean;
}> = (props) => {
    const [selectedRoad, setSelectedRoad] = useState<false | number>(false);

    const zoomRoad = (index: number) => {
        if (selectedRoad === false) {
            setSelectedRoad(index);
        } else {
            setSelectedRoad(false);
        }
    }

    return (
        <>
            <BrowserView>
                <RoadWrapperDiv style={{ transformOrigin: 'bottom right', transform: props.small ? 'scale(0.8)' : undefined, transitionDuration: '300ms' }}>
                    <RoadBallHeaderDiv>
                        <RoadBallHeaderItemDiv selected={selectedRoad === false ? undefined : (selectedRoad === 0 || selectedRoad === 2)}><RoadBallHeaderItemTextDiv>일반볼</RoadBallHeaderItemTextDiv></RoadBallHeaderItemDiv>
                        <RoadBallHeaderSeparatorWrapperDiv><RoadBallHeaderSeparatorDiv selected={selectedRoad !== false} /></RoadBallHeaderSeparatorWrapperDiv>
                        <RoadBallHeaderItemDiv style={{ left: (selectedRoad === 1 || selectedRoad === 3) ? 0 : '51%' }} selected={selectedRoad === false ? undefined : (selectedRoad === 1 || selectedRoad === 3)}><RoadBallHeaderItemTextDiv>파워볼</RoadBallHeaderItemTextDiv></RoadBallHeaderItemDiv>
                    </RoadBallHeaderDiv>
                    <RoadBallContentWrapperDiv>
                        <RoadBallContentDiv>
                            <RoadBallGroupDiv selected={selectedRoad === false ? undefined : (selectedRoad === 0 || selectedRoad === 1)}>
                                <RoadBallGroupHeaderDiv>
                                    <span>홀 / 짝</span>
                                </RoadBallGroupHeaderDiv>
                                <RoadBallGroupContentWrapperDiv>
                                    <RoadBallGroupItemDiv onClick={() => zoomRoad(0)} selected={selectedRoad === false ? undefined : selectedRoad === 0}>
                                        <RoadContentWrapperDiv selected={selectedRoad === false ? undefined : selectedRoad === 0}>
                                            <RoadContentDiv>
                                                <RoadItem colCount={5} rowCount={15} history={props.history.filter((game) => game.ballValues[5] !== null).map((game) => game.ballValues.slice(0, 5).reduce((a, b) => a + b, 0))} type={'ODDEVEN'} />
                                            </RoadContentDiv>
                                        </RoadContentWrapperDiv>
                                    </RoadBallGroupItemDiv>
                                    <RoadBallHeaderSeparatorWrapperDiv><RoadBallHeaderSeparatorDiv selected={selectedRoad === false ? undefined : (selectedRoad === 0 || selectedRoad === 1)} /></RoadBallHeaderSeparatorWrapperDiv>
                                    <RoadBallGroupItemDiv onClick={() => zoomRoad(1)} style={{ left: selectedRoad === 1 ? '0' : '51%' }} selected={selectedRoad === false ? undefined : selectedRoad === 1}>
                                        <RoadContentWrapperDiv selected={selectedRoad === false ? undefined : selectedRoad === 1}>
                                            <RoadContentDiv>
                                                <RoadItem colCount={5} rowCount={15} history={props.history.filter((game) => game.ballValues[5] !== null).map((game) => game.ballValues[5])} type={'ODDEVEN'} />
                                            </RoadContentDiv>
                                        </RoadContentWrapperDiv>
                                    </RoadBallGroupItemDiv>
                                </RoadBallGroupContentWrapperDiv>
                            </RoadBallGroupDiv>
                            <RoadBallGroupDiv selected={selectedRoad === false ? undefined : (selectedRoad === 2 || selectedRoad === 3)} style={{ top: (selectedRoad === 2 || selectedRoad === 3) ? '-100%' : '0' }}>
                                <RoadBallGroupHeaderDiv>
                                    <span>언더 / 오버</span>
                                </RoadBallGroupHeaderDiv>
                                <RoadBallGroupContentWrapperDiv>
                                    <RoadBallGroupItemDiv onClick={() => zoomRoad(2)} selected={selectedRoad === false ? undefined : selectedRoad === 2}>
                                        <RoadContentWrapperDiv selected={selectedRoad === false ? undefined : selectedRoad === 2}>
                                            <RoadContentDiv>
                                                <RoadItem colCount={5} rowCount={15} history={props.history.filter((game) => game.ballValues[5] !== null).map((game) => game.ballValues.slice(0, 5).reduce((a, b) => a + b, 0))} type={'UNOVER_NORMAL'} />
                                            </RoadContentDiv>
                                        </RoadContentWrapperDiv>
                                    </RoadBallGroupItemDiv>
                                    <RoadBallHeaderSeparatorWrapperDiv><RoadBallHeaderSeparatorDiv selected={selectedRoad === false ? undefined : (selectedRoad === 2 || selectedRoad === 3)} /></RoadBallHeaderSeparatorWrapperDiv>
                                    <RoadBallGroupItemDiv onClick={() => zoomRoad(3)} style={{ left: selectedRoad === 3 ? '0' : '51%' }} selected={selectedRoad === false ? undefined : selectedRoad === 3}>
                                        <RoadContentWrapperDiv selected={selectedRoad === false ? undefined : selectedRoad === 3}>
                                            <RoadContentDiv>
                                                <RoadItem colCount={5} rowCount={15} history={props.history.filter((game) => game.ballValues[5] !== null).map((game) => game.ballValues[5])} type={'UNOVER_POWER'} />
                                            </RoadContentDiv>
                                        </RoadContentWrapperDiv>
                                    </RoadBallGroupItemDiv>
                                </RoadBallGroupContentWrapperDiv>
                            </RoadBallGroupDiv>
                        </RoadBallContentDiv>
                    </RoadBallContentWrapperDiv>
                </RoadWrapperDiv>
            </BrowserView>
            <MobileView>
                <MobileRoadWrapperDiv style={{ height: '190px' }}>
                    <RoadBallHeaderDiv>
                        <RoadBallHeaderItemDiv selected={selectedRoad === false ? undefined : (selectedRoad === 0 || selectedRoad === 2)}><RoadBallHeaderItemTextDiv>일반볼</RoadBallHeaderItemTextDiv></RoadBallHeaderItemDiv>
                        <RoadBallHeaderSeparatorWrapperDiv><RoadBallHeaderSeparatorDiv selected={selectedRoad !== false} /></RoadBallHeaderSeparatorWrapperDiv>
                        <RoadBallHeaderItemDiv style={{ left: (selectedRoad === 1 || selectedRoad === 3) ? 0 : '51%' }} selected={selectedRoad === false ? undefined : (selectedRoad === 1 || selectedRoad === 3)}><RoadBallHeaderItemTextDiv>파워볼</RoadBallHeaderItemTextDiv></RoadBallHeaderItemDiv>
                    </RoadBallHeaderDiv>
                    <RoadBallContentWrapperDiv>
                        <RoadBallContentDiv>
                            <RoadBallGroupDiv selected={selectedRoad === false ? undefined : (selectedRoad === 0 || selectedRoad === 1)}>
                                <RoadBallGroupHeaderDiv>
                                    <span>홀 / 짝</span>
                                </RoadBallGroupHeaderDiv>
                                <RoadBallGroupContentWrapperDiv>
                                    <RoadBallGroupItemDiv onClick={() => zoomRoad(0)} selected={selectedRoad === false ? undefined : selectedRoad === 0}>
                                        <RoadContentWrapperDiv selected={selectedRoad === false ? undefined : selectedRoad === 0}>
                                            <MobileRoadContentDiv>
                                                <RoadItem colCount={5} rowCount={16} history={props.history.filter((game) => game.ballValues[5] !== null).map((game) => game.ballValues.slice(0, 5).reduce((a, b) => a + b, 0))} type={'ODDEVEN'} />
                                            </MobileRoadContentDiv>
                                        </RoadContentWrapperDiv>
                                    </RoadBallGroupItemDiv>
                                    <RoadBallHeaderSeparatorWrapperDiv><RoadBallHeaderSeparatorDiv selected={selectedRoad === false ? undefined : (selectedRoad === 0 || selectedRoad === 1)} /></RoadBallHeaderSeparatorWrapperDiv>
                                    <RoadBallGroupItemDiv onClick={() => zoomRoad(1)} style={{ left: selectedRoad === 1 ? '0' : '51%' }} selected={selectedRoad === false ? undefined : selectedRoad === 1}>
                                        <RoadContentWrapperDiv selected={selectedRoad === false ? undefined : selectedRoad === 1}>
                                            <MobileRoadContentDiv>
                                                <RoadItem colCount={5} rowCount={16} history={props.history.filter((game) => game.ballValues[5] !== null).map((game) => game.ballValues[5])} type={'ODDEVEN'} />
                                            </MobileRoadContentDiv>
                                        </RoadContentWrapperDiv>
                                    </RoadBallGroupItemDiv>
                                </RoadBallGroupContentWrapperDiv>
                            </RoadBallGroupDiv>
                            <RoadBallGroupDiv selected={selectedRoad === false ? undefined : (selectedRoad === 2 || selectedRoad === 3)} style={{ top: (selectedRoad === 2 || selectedRoad === 3) ? '-100%' : '0' }}>
                                <RoadBallGroupHeaderDiv>
                                    <span>언더 / 오버</span>
                                </RoadBallGroupHeaderDiv>
                                <RoadBallGroupContentWrapperDiv>
                                    <RoadBallGroupItemDiv onClick={() => zoomRoad(2)} selected={selectedRoad === false ? undefined : selectedRoad === 2}>
                                        <RoadContentWrapperDiv selected={selectedRoad === false ? undefined : selectedRoad === 2}>
                                            <MobileRoadContentDiv>
                                                <RoadItem colCount={5} rowCount={16} history={props.history.filter((game) => game.ballValues[5] !== null).map((game) => game.ballValues.slice(0, 5).reduce((a, b) => a + b, 0))} type={'UNOVER_NORMAL'} />
                                            </MobileRoadContentDiv>
                                        </RoadContentWrapperDiv>
                                    </RoadBallGroupItemDiv>
                                    <RoadBallHeaderSeparatorWrapperDiv><RoadBallHeaderSeparatorDiv selected={selectedRoad === false ? undefined : (selectedRoad === 2 || selectedRoad === 3)} /></RoadBallHeaderSeparatorWrapperDiv>
                                    <RoadBallGroupItemDiv onClick={() => zoomRoad(3)} style={{ left: selectedRoad === 3 ? '0' : '51%' }} selected={selectedRoad === false ? undefined : selectedRoad === 3}>
                                        <RoadContentWrapperDiv selected={selectedRoad === false ? undefined : selectedRoad === 3}>
                                            <MobileRoadContentDiv>
                                                <RoadItem colCount={5} rowCount={16} history={props.history.filter((game) => game.ballValues[5] !== null).map((game) => game.ballValues[5])} type={'UNOVER_POWER'} />
                                            </MobileRoadContentDiv>
                                        </RoadContentWrapperDiv>
                                    </RoadBallGroupItemDiv>
                                </RoadBallGroupContentWrapperDiv>
                            </RoadBallGroupDiv>
                        </RoadBallContentDiv>
                    </RoadBallContentWrapperDiv>
                </MobileRoadWrapperDiv>
            </MobileView>
        </>
    );
}