import styled from "styled-components";
import {createRef, FunctionComponent, useState} from "react";

import stat from './stat.svg';
import close from './close.svg';
import {Ball, SumTextDiv, SumWrapperDiv} from "../CurrentBalls";
import {BrowserView, MobileView} from "react-device-detect";

const StatsWrapperDiv = styled.div<{ statHidden: boolean }>`
  position: absolute;
  width: 400px;
  right: 0;
  bottom: 55px;
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  background: rgba(26,26,26,.8);
  border: 1px solid rgba(0,0,0,.1);
  border-collapse: collapse;
  border-radius: 1rem 0 0 0;
  box-sizing: border-box;
  height: 700px;
  -webkit-mask-image: linear-gradient(#1a1a1a 95%,rgba(26,26,26,.8),rgba(26,26,26,.6),rgba(26,26,26,.2),transparent);
  mask-image: linear-gradient(#1a1a1a 95%,rgba(26,26,26,.8),rgba(26,26,26,.6),rgba(26,26,26,.2),transparent);
  transition-duration: 500ms;
  transition-property: transform,opacity;
  pointer-events: auto;

  transform: none;
  opacity: 1;
  ${props => props.statHidden ? `
    transform: translateY(15%);
    opacity: 0;
    pointer-events: none;
  ` : ``}
`;
const StatsHeaderDiv = styled.div`
  margin-top: 15px;
  font-size: 22px;
  color: white;
  text-align: center;
  
  & > img {
    width: 30px;
    height: 30px;
    line-height: 30px;
    vertical-align: middle;
  }
  & > div {
    vertical-align: middle;
    display: inline-block;
    margin-left: 5px;
    line-height: 30px;
  }
`;
const CloseButton = styled.button`
  position: absolute;
  background: transparent;
  border: none;
  right: 10px;
  top: 13px;
  width: 35px;
  height: 35px;
  
  & > img {
    width: 100%;
  }
`;
const StatsSectionHeader = styled.div`
    color: #fff;
    font-weight: 400;
    opacity: 0.6;
    font-size: 11px;
    margin-left: 15px;
    margin-top: 20px;
`;
const StatsSection = styled.div`
  background: hsla(0, 0%, 100%, 0.05);
  border-radius: 15px;
  width: calc(100% - 30px);
  box-sizing: border-box;
  padding: 10px;
  margin-left: 15px;
  margin-top: 7px;
`;

const PercentHeader = styled.div`
    text-align: center;
    background: hsla(0, 0%, 56%, 0.4);
    border-radius: 15px;
    height: 18px;
    width: 100%;
    font-size: 12px;
    line-height: 18px;
    color: white;
    text-shadow: 0.5px 0.5px 0 rgba(0,0,0,.5);
`;
const SliderWrapper = styled.div<{ value: number }>`
    grid-row-gap: 6%;
    display: inline-grid;
    font-size: 12px;
    grid-template-columns: minmax(-webkit-min-content, ${props => props.value}%) minmax(-webkit-min-content,auto);
    grid-template-columns: minmax(min-content, ${props => props.value}%) minmax(min-content,auto);
    grid-template-rows: 1fr 1fr;
    height: 40px;
    width: calc(50% - 3px);
    margin-top: 5px;
`;
const RedSlider = styled.div`
    align-items: center;
    color: hsla(0,0%,100%,.9);
    display: flex;
    font-weight: 600;
    justify-content: center;
    letter-spacing: -.05em;
    padding: 0 0.15em;
    position: relative;
    text-shadow: 1px 1px 0 rgba(0,0,0,.4);
    white-space: nowrap;
    background: radial-gradient(88% 68% at 50% .4%,hsla(0,88%,73%,.85),transparent) border-box,linear-gradient(180deg,rgba(232,53,53,.6),rgba(139,21,31,.6)) border-box;
    border-radius: 0 15px 15px 0;
  
    &:before {
      background: linear-gradient(180deg,#a72f38 5%,#f76a6a 57%,#b15759) border-box;
      border: 1px solid transparent;
      border-radius: inherit;
      box-sizing: border-box;
      content: "";
      height: 100%;
      left: 0;
      -webkit-mask-clip: padding-box, border-box;
      mask-clip: padding-box,border-box;
      -webkit-mask-composite: clear;
      -webkit-mask-composite: xor;
      mask-composite: exclude;
      -webkit-mask-image: linear-gradient(#fff,#fff),linear-gradient(#fff,#fff);
      mask-image: linear-gradient(#fff,#fff),linear-gradient(#fff,#fff);
      -webkit-mask-origin: padding-box,border-box;
      mask-origin: padding-box,border-box;
      position: absolute;
      top: 0;
      width: 100%;
    }
`;
const BlueSlider = styled.div`
    align-items: center;
    color: hsla(0,0%,100%,.9);
    display: flex;
    font-weight: 600;
    justify-content: center;
    letter-spacing: -.05em;
    padding: 0 0.15em;
    position: relative;
    text-shadow: 1px 1px 0 rgba(0,0,0,.4);
    white-space: nowrap;
    background: radial-gradient(94% 63% at 50% .4%,rgba(161,209,227,.85),transparent) border-box,linear-gradient(180deg,rgba(71,176,209,.6),rgba(42,69,96,.6)) border-box;
    border-radius: 15px 0 0 15px;
  
    &:before {
      background: linear-gradient(180deg,#20647b 5%,#6ca8bf 46%,#3d6685) border-box;
      border: 1px solid transparent;
      border-radius: inherit;
      box-sizing: border-box;
      content: "";
      height: 100%;
      left: 0;
      -webkit-mask-clip: padding-box, border-box;
      mask-clip: padding-box,border-box;
      -webkit-mask-composite: clear;
      -webkit-mask-composite: xor;
      mask-composite: exclude;
      -webkit-mask-image: linear-gradient(#fff,#fff),linear-gradient(#fff,#fff);
      mask-image: linear-gradient(#fff,#fff),linear-gradient(#fff,#fff);
      -webkit-mask-origin: padding-box,border-box;
      mask-origin: padding-box,border-box;
      position: absolute;
      top: 0;
      width: 100%;
    }
`;
const SliderTitle = styled.div`
    align-items: center;
    color: #fff;
    display: flex;
    font-weight: 500;
    justify-content: center;
    white-space: nowrap;
`;

const StatsTableHeader = styled.div`
    display: flex;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
`;
const StatsTableColumn = styled.div`
    font-size: 12px;
    color: white;
    opacity: 0.6;
    height: 30px;
    line-height: 23px;
    padding-left: 5px;
`;
const StatsTableContent = styled.div`
    width: 341px;
    height: 310px;
    overflow-y: scroll;
    
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background: rgba(0,0,0,0.1);
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: rgba(0,0,0,0.2);
    }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.4);
  }
  &::-webkit-scrollbar-thumb:active {
    background: rgba(0, 0, 0, 0.9);
  }
`;
const StatsTableRow = styled.div`
    display: flex;
`;

export const Stats: FunctionComponent<{ history: {
        ballValues: number[];
        gameId: string;
        gameNumber: string;
        roundId: number;
        roundIds: number[];
    }[], show: boolean, onClose: () => unknown }> = (props) => {
    const [rowStart, setRowStart] = useState<number>(0);
    const [rowEnd, setRowEnd] = useState<number>(20);

    const tableRef = createRef<HTMLDivElement>();

    const updateVirtualContent = () => {
        const table = tableRef.current;
        if (!table) return;

        const _ROW_HEIGHT = 30;
        const currentScrollTop = table.scrollTop;

        const rowStart = Math.floor(currentScrollTop / _ROW_HEIGHT);
        const rowEnd = rowStart + 20;

        setRowStart(rowStart);
        setRowEnd(rowEnd);
    }

    return (
        <>
            <BrowserView>
                <StatsWrapperDiv statHidden={!props.show}>
                    <StatsHeaderDiv>
                        <img src={stat} alt="Stats" />
                        <div>통계</div>
                    </StatsHeaderDiv>
                    <CloseButton onClick={() => props.onClose()}><img src={close} /></CloseButton>
                    <div>
                        <StatsSectionHeader>통계</StatsSectionHeader>
                        <StatsSection style={{ height: '145px' }}>
                            <PercentHeader>일반볼</PercentHeader>
                            <SliderWrapper value={Math.round(props.history.filter((game) => game.ballValues.slice(0, 5).reduce((curr, sum) => curr + sum, 0) % 2 === 1).length / props.history.length * 100)}>
                                <BlueSlider>{Math.round(props.history.filter((game) => game.ballValues.slice(0, 5).reduce((curr, sum) => curr + sum, 0) % 2 === 1).length / props.history.length * 100)}%</BlueSlider>
                                <RedSlider>{Math.round(props.history.filter((game) => game.ballValues.slice(0, 5).reduce((curr, sum) => curr + sum, 0) % 2 === 0).length / props.history.length * 100)}%</RedSlider>
                                <SliderTitle>홀</SliderTitle>
                                <SliderTitle>짝</SliderTitle>
                            </SliderWrapper>
                            <SliderWrapper value={Math.round(props.history.filter((game) => game.ballValues.slice(0, 5).reduce((curr, sum) => curr + sum, 0) <= 72).length / props.history.length * 100)} style={{ marginLeft: '6px' }}>
                                <BlueSlider>{Math.round(props.history.filter((game) => game.ballValues.slice(0, 5).reduce((curr, sum) => curr + sum, 0) <= 72).length / props.history.length * 100)}%</BlueSlider>
                                <RedSlider>{Math.round(props.history.filter((game) => game.ballValues.slice(0, 5).reduce((curr, sum) => curr + sum, 0) >= 73).length / props.history.length * 100)}%</RedSlider>
                                <SliderTitle>언더</SliderTitle>
                                <SliderTitle>오버</SliderTitle>
                            </SliderWrapper>

                            <PercentHeader style={{ marginTop: '5px' }}>파워볼</PercentHeader>
                            <SliderWrapper value={Math.round(props.history.filter((game) => game.ballValues[5] % 2 === 1).length / props.history.length * 100)}>
                                <BlueSlider>{Math.round(props.history.filter((game) => game.ballValues[5] % 2 === 1).length / props.history.length * 100)}%</BlueSlider>
                                <RedSlider>{Math.round(props.history.filter((game) => game.ballValues[5] % 2 === 0).length / props.history.length * 100)}%</RedSlider>
                                <SliderTitle>홀</SliderTitle>
                                <SliderTitle>짝</SliderTitle>
                            </SliderWrapper>
                            <SliderWrapper value={Math.round(props.history.filter((game) => game.ballValues[5] <= 4).length / props.history.length * 100)} style={{ marginLeft: '6px' }}>
                                <BlueSlider>{Math.round(props.history.filter((game) => game.ballValues[5] <= 4).length / props.history.length * 100)}%</BlueSlider>
                                <RedSlider>{Math.round(props.history.filter((game) => game.ballValues[5] >= 5).length / props.history.length * 100)}%</RedSlider>
                                <SliderTitle>언더</SliderTitle>
                                <SliderTitle>오버</SliderTitle>
                            </SliderWrapper>
                        </StatsSection>
                        <StatsSectionHeader>볼 기록</StatsSectionHeader>
                        <StatsSection style={{ height: '370px', padding: '15px' }}>
                            <StatsTableHeader>
                                <StatsTableColumn style={{ flex: '2' }}>라운드</StatsTableColumn>
                                <StatsTableColumn style={{ flex: '4' }}>일반볼 머신</StatsTableColumn>
                                <StatsTableColumn style={{ flex: '1' }}>파워볼</StatsTableColumn>
                            </StatsTableHeader>
                            <StatsTableContent onScroll={() => updateVirtualContent()} ref={tableRef}>
                                {Array(500).fill(0).filter((_, index) => props.history.slice().reverse()[index]).map((_, index) => props.history.slice().reverse()[index].ballValues[5] !== null ? (
                                    <StatsTableRow>
                                        <StatsTableColumn style={{ flex: '2', height: '30px', lineHeight: '30px' }}>
                                            {index >= rowStart && index < rowEnd ? `# ${props.history.slice().reverse()[index].roundId}` : ''}
                                        </StatsTableColumn>
                                        <StatsTableColumn style={{ flex: '4', height: '30px', lineHeight: '30px', opacity: 1 }}>
                                            {index >= rowStart && index < rowEnd ? props.history.slice().reverse()[index].ballValues.slice(0, 5).map((ball) => (
                                                <Ball style={{ width: '22px', height: '22px', fontSize: '10px', lineHeight: '21px' }} type={ball % 4 === 0 ? 'blue' : ball % 4 === 1 ? 'red' : ball % 4 === 2 ? 'yellow' : 'green'}>{ball === -1 ? '' : ball}</Ball>
                                            )) : ''}
                                            {index >= rowStart && index < rowEnd ? <SumWrapperDiv style={{ width: '27px', height: '22px', lineHeight: '21px' }} type={'final'}>
                                                <SumTextDiv style={{ height: '22px', lineHeight: '15px', fontSize: '11px' }} type={'final'}>{props.history.slice().reverse()[index].ballValues.slice(0, 5).reduce((a, b) => a + b, 0)}</SumTextDiv>
                                            </SumWrapperDiv> : ''}
                                        </StatsTableColumn>
                                        <StatsTableColumn style={{ flex: '1', height: '30px', lineHeight: '30px', opacity: 1 }}>
                                            {index >= rowStart && index < rowEnd ? <Ball style={{ width: '22px', height: '22px', fontSize: '10px', lineHeight: '21px' }} type={'power'}>{props.history.slice().reverse()[index].ballValues[5]}</Ball> : ''}
                                        </StatsTableColumn>
                                    </StatsTableRow>
                                ) : null)}
                            </StatsTableContent>
                        </StatsSection>
                    </div>
                </StatsWrapperDiv>
            </BrowserView>
            <MobileView>
                <StatsWrapperDiv statHidden={!props.show} style={{ width: '100%', borderRadius: '0' }}>
                    <StatsHeaderDiv>
                        <img src={stat} alt="Stats" />
                        <div>통계</div>
                    </StatsHeaderDiv>
                    <CloseButton onClick={() => props.onClose()}><img src={close} /></CloseButton>
                    <div>
                        <StatsSectionHeader>통계</StatsSectionHeader>
                        <StatsSection style={{ height: '145px' }}>
                            <PercentHeader>일반볼</PercentHeader>
                            <SliderWrapper value={Math.round(props.history.filter((game) => game.ballValues.slice(0, 5).reduce((curr, sum) => curr + sum, 0) % 2 === 1).length / props.history.length * 100)}>
                                <BlueSlider>{Math.round(props.history.filter((game) => game.ballValues.slice(0, 5).reduce((curr, sum) => curr + sum, 0) % 2 === 1).length / props.history.length * 100)}%</BlueSlider>
                                <RedSlider>{Math.round(props.history.filter((game) => game.ballValues.slice(0, 5).reduce((curr, sum) => curr + sum, 0) % 2 === 0).length / props.history.length * 100)}%</RedSlider>
                                <SliderTitle>홀</SliderTitle>
                                <SliderTitle>짝</SliderTitle>
                            </SliderWrapper>
                            <SliderWrapper value={Math.round(props.history.filter((game) => game.ballValues.slice(0, 5).reduce((curr, sum) => curr + sum, 0) <= 72).length / props.history.length * 100)} style={{ marginLeft: '6px' }}>
                                <BlueSlider>{Math.round(props.history.filter((game) => game.ballValues.slice(0, 5).reduce((curr, sum) => curr + sum, 0) <= 72).length / props.history.length * 100)}%</BlueSlider>
                                <RedSlider>{Math.round(props.history.filter((game) => game.ballValues.slice(0, 5).reduce((curr, sum) => curr + sum, 0) >= 73).length / props.history.length * 100)}%</RedSlider>
                                <SliderTitle>언더</SliderTitle>
                                <SliderTitle>오버</SliderTitle>
                            </SliderWrapper>

                            <PercentHeader style={{ marginTop: '5px' }}>파워볼</PercentHeader>
                            <SliderWrapper value={Math.round(props.history.filter((game) => game.ballValues[5] % 2 === 1).length / props.history.length * 100)}>
                                <BlueSlider>{Math.round(props.history.filter((game) => game.ballValues[5] % 2 === 1).length / props.history.length * 100)}%</BlueSlider>
                                <RedSlider>{Math.round(props.history.filter((game) => game.ballValues[5] % 2 === 0).length / props.history.length * 100)}%</RedSlider>
                                <SliderTitle>홀</SliderTitle>
                                <SliderTitle>짝</SliderTitle>
                            </SliderWrapper>
                            <SliderWrapper value={Math.round(props.history.filter((game) => game.ballValues[5] <= 4).length / props.history.length * 100)} style={{ marginLeft: '6px' }}>
                                <BlueSlider>{Math.round(props.history.filter((game) => game.ballValues[5] <= 4).length / props.history.length * 100)}%</BlueSlider>
                                <RedSlider>{Math.round(props.history.filter((game) => game.ballValues[5] >= 5).length / props.history.length * 100)}%</RedSlider>
                                <SliderTitle>언더</SliderTitle>
                                <SliderTitle>오버</SliderTitle>
                            </SliderWrapper>
                        </StatsSection>
                        <StatsSectionHeader>볼 기록</StatsSectionHeader>
                        <StatsSection style={{ height: '370px', padding: '15px' }}>
                            <StatsTableHeader>
                                <StatsTableColumn style={{ flex: '2' }}>라운드</StatsTableColumn>
                                <StatsTableColumn style={{ flex: '4' }}>일반볼 머신</StatsTableColumn>
                                <StatsTableColumn style={{ flex: '1' }}>파워볼</StatsTableColumn>
                            </StatsTableHeader>
                            <StatsTableContent onScroll={() => updateVirtualContent()} ref={tableRef}>
                                {Array(500).fill(0).filter((_, index) => props.history.slice().reverse()[index]).map((_, index) => props.history.slice().reverse()[index].ballValues[5] !== null ? (
                                    <StatsTableRow>
                                        <StatsTableColumn style={{ flex: '2', height: '30px', lineHeight: '30px' }}>
                                            {index >= rowStart && index < rowEnd ? `# ${props.history.slice().reverse()[index].roundId}` : ''}
                                        </StatsTableColumn>
                                        <StatsTableColumn style={{ flex: '4', height: '30px', lineHeight: '30px', opacity: 1 }}>
                                            {index >= rowStart && index < rowEnd ? props.history.slice().reverse()[index].ballValues.slice(0, 5).map((ball) => (
                                                <Ball style={{ width: '22px', height: '22px', fontSize: '10px', lineHeight: '21px' }} type={ball % 4 === 0 ? 'blue' : ball % 4 === 1 ? 'red' : ball % 4 === 2 ? 'yellow' : 'green'}>{ball === -1 ? '' : ball}</Ball>
                                            )) : ''}
                                            {index >= rowStart && index < rowEnd ? <SumWrapperDiv style={{ width: '27px', height: '22px', lineHeight: '21px' }} type={'final'}>
                                                <SumTextDiv style={{ height: '22px', lineHeight: '15px', fontSize: '11px' }} type={'final'}>{props.history.slice().reverse()[index].ballValues.slice(0, 5).reduce((a, b) => a + b, 0)}</SumTextDiv>
                                            </SumWrapperDiv> : ''}
                                        </StatsTableColumn>
                                        <StatsTableColumn style={{ flex: '1', height: '30px', lineHeight: '30px', opacity: 1 }}>
                                            {index >= rowStart && index < rowEnd ? <Ball style={{ width: '22px', height: '22px', fontSize: '10px', lineHeight: '21px' }} type={'power'}>{props.history.slice().reverse()[index].ballValues[5]}</Ball> : ''}
                                        </StatsTableColumn>
                                    </StatsTableRow>
                                ) : null)}
                            </StatsTableContent>
                        </StatsSection>
                    </div>
                </StatsWrapperDiv>
            </MobileView>
        </>
    );
}