import {FunctionComponent} from "react";
import styled from "styled-components";
import {BrowserView, MobileView} from "react-device-detect";

interface HeaderProps {
    minBet: number;
    maxBet: number;
}

const HeaderElement = styled.header`
    position: absolute;
    width: 100%;
    height: 100%;
    font-size: 19px;
    padding: 15px 20px;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,0.7) 0%, rgba(255,255,255,0) 10%, rgba(255,255,255,0) 90%, rgba(0,0,0,0.7) 100%);
`;
const TableNameSpan = styled.span`
    color: white;
    text-shadow: 1px 1px 0 rgba(0,0,0,.6);
`;
const BetAmountRangeSpan = styled.span`
    color: #fbdc01;
    text-shadow: 1px 1px 0 rgba(0,0,0,.6);
`;

const MobileHeaderElement = styled.header`
    position: absolute;
    bottom: 10px;
    right: 8px;
    font-size: 14px;
    font-weight: 600;
`;

export const Header: FunctionComponent<HeaderProps> = (props) => {
    return (
        <>
            <BrowserView>
                <HeaderElement>
                    <TableNameSpan>스피드파워볼</TableNameSpan> <BetAmountRangeSpan>₩{props.minBet.toLocaleString()} – {props.maxBet.toLocaleString()}</BetAmountRangeSpan>
                </HeaderElement>
            </BrowserView>
            <MobileView>
                <MobileHeaderElement>
                    <TableNameSpan>스피드파워볼</TableNameSpan> <BetAmountRangeSpan>₩{props.minBet.toLocaleString()} – {props.maxBet.toLocaleString()}</BetAmountRangeSpan>
                </MobileHeaderElement>
            </MobileView>
        </>
    );
}