import {FunctionComponent, useEffect, useState} from "react";
import styled from "styled-components";
import { _API_KEY, _API_URL } from '../../App';

import history_white from './history_white.svg';
import close from './close.svg';
import {Ball, SumTextDiv, SumWrapperDiv} from "../CurrentBalls";
import {BrowserView, MobileView} from "react-device-detect";

const _BET_ITEMS = {
    RegularOdd: '일반볼 홀', RegularEven: '일반볼 짝',
    PowerOdd: '파워볼 홀', PowerEven: '파워볼 짝',
    RegularHigh: '일반볼 대', RegularMedium: '일반볼 중', RegularLow: '일반볼 소',
    RegularOddUnder: '일반볼 홀언더', RegularOddOver: '일반볼 홀오버', RegularEvenUnder: '일반볼 짝언더', RegularEvenOver: '일반볼 짝오버',
    RegularUnder: '일반볼 언더', RegularOver: '일반볼 오버',
    PowerUnder: '파워볼 언더', PowerOver: '파워볼 오버',
    RegularOddHigh: '일반볼 홀대', RegularOddMedium: '일반볼 홀중', RegularOddLow: '일반볼 홀소', RegularEvenHigh: '일반볼 짝대', RegularEvenMedium: '일반볼 짝중', RegularEvenLow: '일반볼 짝소',
    PowerOddUnder: '파워볼 홀언더', PowerOddOver: '파워볼 홀오버', PowerEvenUnder: '파워볼 짝언더', PowerEvenOver: '파워볼 짝오버',
    Powerball0: '파워볼 0', Powerball1: '파워볼 1', Powerball2: '파워볼 2', Powerball3: '파워볼 3', Powerball4: '파워볼 4', Powerball5: '파워볼 5', Powerball6: '파워볼 6', Powerball7: '파워볼 7', Powerball8: '파워볼 8', Powerball9: '파워볼 9',
    RegularOddPowerOdd: '일반볼 홀 + 파워볼 홀', RegularOddPowerEven: '일반볼 홀 + 파워볼 짝', RegularEvenPowerOdd: '일반볼 짝 + 파워볼 홀', RegularEvenPowerEven: '일반볼 짝 + 파워볼 짝',
};

const BetHistoryWrapper = styled.div<{ isOpen: boolean }>`
    position: absolute;
    right: 0;
    top: 45px;
    width: 550px;
    height: 440px;
  
    max-width: 100%;
  
    pointer-events: auto;
    transition: right 0.3s ease-in-out;
  
    ${props => !props.isOpen ? `
        right: -550px;    
    ` : ''}
`;
const BetHistoryHeader = styled.div`
    background-color: hsla(0,0%,42%,.85);
    font-size: 14px;
    font-weight: 400;
    height: 30px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    color: white;
    text-align: center;
    line-height: 26px;
  
    & > img {
      width: 20px;
      vertical-align: middle;
    }
    & > div {
      display: inline-block;
      line-height: 20px;
      vertical-align: middle;
      height: 20px;
    }
`;
const BetHistoryContent = styled.div`
    background-color: rgba(26, 26, 26, 0.8);
    height: 410px;
    overflow-y: auto;
`;
const CloseButtonWrapper = styled.button`
    position: absolute;
    right: -3px;
    cursor: pointer;
    top: 4px;
    border: none;
    background: transparent;
  
    & > img {
        width: 20px;
    }
`;
const BetHistoryTable = styled.table`
    width: 100%;
    color: white;
    font-size: 14px;
    border-collapse: collapse;
  
    & > thead > tr > th {
        font-weight: 400;
    }
    & > tbody > tr > td:first-child, & > thead > tr > th:first-child {
        padding-left: 10px;
    }
    & > tbody > tr > td:last-child, & > thead > tr > th:last-child {
        padding-right: 10px;
    }
`;
const BetHistoryTableDateRow = styled.tr`
    background-color: rgba(255, 255, 255, 0.1);
`;

export const BetHistory: FunctionComponent<{ isOpen: boolean; onClose?: () => unknown }> = (props) => {
    const [history, setHistory] = useState<{
        day: string;
        betSum: number;
        profitSum: number;
        games: {
            gameId: string;
            dayRound: number;
            betSum: number;
            profitSum: number;
            bets: Record<string, string>[];
            gameNumber: string;
            autoBet: boolean;
            balls: number[];
            created: string;
            createdFormatted: string;
        }[];
    }[]>([]);
    const [selectedGame, setSelectedGame] = useState<{
        gameId: string;
        dayRound: number;
        betSum: number;
        profitSum: number;
        bets: Record<string, string>[];
        gameNumber: string;
        autoBet: boolean;
        balls: number[];
        created: string;
        createdFormatted: string;
    } | undefined>();

    useEffect(() => {
        const loadHistory = async () => {
            if (_API_KEY === 'GUEST') {
                return;
            }
            const request = await fetch(`${_API_URL}/bet/history?token=${_API_KEY}`);
            const response = await request.json();

            if (!response.success) {
                throw new Error('Failed to load bet history.');
            }

            const bets = response.bets;
            const games: Record<string, {
                gameId: string;
                dayRound: number;
                betSum: number;
                profitSum: number;
                bets: Record<string, string>[];
                gameNumber: string;
                autoBet: boolean;
                balls: number[];
                created: string;
                createdFormatted: string;
            }> = {};
            for (const bet of bets) {
                if (bet.gameId in games) {
                    games[bet.gameId].bets.push(bet);
                    games[bet.gameId].betSum += bet.amount;
                    games[bet.gameId].profitSum += bet.profit;
                } else {
                    const date = new Date(bet.created);
                    const formatted = `${date.getHours() < 12 ? '오전' : '오후'} ${date.getHours() % 12 === 0 ? 12 : date.getHours()}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;

                    games[bet.gameId] = {
                        gameId: bet.gameId,
                        dayRound: bet.dayRound,
                        betSum: bet.amount,
                        profitSum: bet.profit,
                        bets: [bet],
                        gameNumber: bet.gameNumber,
                        autoBet: bet.autoBet,
                        balls: bet.balls,
                        created: bet.created,
                        createdFormatted: formatted,
                    };
                }
            }

            const gameByDay: Record<string, {
                day: string;
                betSum: number;
                profitSum: number;
                games: {
                    gameId: string;
                    dayRound: number;
                    betSum: number;
                    profitSum: number;
                    bets: Record<string, string>[];
                    gameNumber: string;
                    autoBet: boolean;
                    balls: number[];
                    created: string;
                    createdFormatted: string;
                }[];
            }> = {};
            for (const gameId in games) {
                const game = games[gameId];
                const created = new Date(game.created);
                const day = `${created.getFullYear()}. ${created.getMonth() + 1}. ${created.getDate()}.`;

                if (day in gameByDay) {
                    gameByDay[day].games.push(game);
                    gameByDay[day].betSum += game.betSum;
                    gameByDay[day].profitSum += game.profitSum;
                } else {
                    gameByDay[day] = {
                        day,
                        betSum: game.betSum,
                        profitSum: game.profitSum,
                        games: [game],
                    };
                }
            }

            setHistory(Object.values(gameByDay));
        }

        if (props.isOpen) {
            setSelectedGame(undefined);
            loadHistory().then();
        }
    }, [props.isOpen]);

    return (
        <BetHistoryWrapper isOpen={props.isOpen}>
            <BetHistoryHeader>
                <img src={history_white} /> <div>게임 기록</div>
                <CloseButtonWrapper onClick={() => props.onClose && props.onClose()}><img src={close} /></CloseButtonWrapper>
            </BetHistoryHeader>
            <BetHistoryContent>
                {selectedGame ? (
                    <div style={{ paddingBottom: '60px' }}>
                        <div style={{ color: 'white', fontWeight: 400, fontSize: '15px', padding: '5px 8px' }}>
                            테이블 : 스피드파워볼 {selectedGame.autoBet ? '(오토베팅)' : ''}<br />
                            게임 번호 : #{selectedGame.dayRound}
                        </div>
                        <BrowserView>
                            <table style={{ width: '100%', borderCollapse: 'collapse', tableLayout: 'fixed' }}>
                                <tbody>
                                    <tr style={{ borderTop: '1px solid rgba(255, 255, 255, 0.4)', borderBottom: '1px solid rgba(255, 255, 255, 0.4)', backgroundColor: 'rgba(0, 0, 0, 0.3)', textAlign: 'center', fontSize: '14px', color: 'white' }}>
                                        <td style={{ width: '50%' }}>일반볼</td>
                                        <td style={{ width: '50%' }}>파워볼</td>
                                    </tr>
                                    <tr style={{ height: '60px', textAlign: 'center' }}>
                                        <td>
                                            {selectedGame.balls.slice(0, 5).map((ball) => (
                                                <Ball type={ball % 4 === 0 ? 'blue' : ball % 4 === 1 ? 'red' : ball % 4 === 2 ? 'yellow' : 'green'}>{ball}</Ball>
                                            ))}
                                            <SumWrapperDiv type={'final'} style={{ marginRight: 0 }}><SumTextDiv type={'final'}>{selectedGame.balls.slice(0, 5).reduce((curr, sum) => sum + curr, 0)}</SumTextDiv></SumWrapperDiv>
                                        </td>
                                        <td>
                                            <Ball type={'power'}>{selectedGame.balls[5]}</Ball>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </BrowserView>
                        <MobileView>
                            <table style={{ width: '100%', borderCollapse: 'collapse', tableLayout: 'fixed' }}>
                                <tbody>
                                    <tr style={{ borderTop: '1px solid rgba(255, 255, 255, 0.4)', borderBottom: '1px solid rgba(255, 255, 255, 0.4)', backgroundColor: 'rgba(0, 0, 0, 0.3)', textAlign: 'center', fontSize: '14px', color: 'white' }}>
                                        <td>일반볼</td>
                                    </tr>
                                    <tr style={{ height: '60px', textAlign: 'center' }}>
                                        <td>
                                            {selectedGame.balls.slice(0, 5).map((ball) => (
                                                <Ball type={ball % 4 === 0 ? 'blue' : ball % 4 === 1 ? 'red' : ball % 4 === 2 ? 'yellow' : 'green'}>{ball}</Ball>
                                            ))}
                                            <SumWrapperDiv type={'final'} style={{ marginRight: 0 }}><SumTextDiv type={'final'}>{selectedGame.balls.slice(0, 5).reduce((curr, sum) => sum + curr, 0)}</SumTextDiv></SumWrapperDiv>
                                        </td>
                                    </tr>
                                    <tr style={{ borderTop: '1px solid rgba(255, 255, 255, 0.4)', borderBottom: '1px solid rgba(255, 255, 255, 0.4)', backgroundColor: 'rgba(0, 0, 0, 0.3)', textAlign: 'center', fontSize: '14px', color: 'white' }}>
                                        <td>파워볼</td>
                                    </tr>
                                    <tr style={{ height: '60px', textAlign: 'center' }}>
                                        <td>
                                            <Ball type={'power'}>{selectedGame.balls[5]}</Ball>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </MobileView>
                        <table style={{ width: '100%', borderCollapse: 'collapse', tableLayout: 'fixed' }}>
                            <tbody>
                                <tr style={{ borderTop: '1px solid rgba(255, 255, 255, 0.4)', backgroundColor: 'rgba(0, 0, 0, 0.3)', textAlign: 'center', fontSize: '14px', color: 'white' }}>
                                    <td style={{ width: '60%', textAlign: 'left', paddingLeft: '5px' }}>베팅 유형</td><td style={{ width: '20%', textAlign: 'right', borderLeft: '1px solid rgba(255, 255, 255, 0.4)', borderRight: '1px solid rgba(255, 255, 255, 0.4)', paddingRight: '5px' }}>베팅</td><td style={{ width: '20%', textAlign: 'right', paddingRight: '5px' }}>승</td>
                                </tr>
                                {selectedGame.bets.map((bet) => (
                                    <tr style={{ fontSize: '14px', color: 'white', backgroundColor: 'rgba(255, 255, 255, 0.1)' }}>
                                        <td style={{ paddingLeft: '5px' }}>{_BET_ITEMS[bet.item as keyof typeof _BET_ITEMS]}</td>
                                        <td style={{ textAlign: 'right', paddingRight: '5px', borderLeft: '1px solid rgba(255, 255, 255, 0.4)', borderRight: '1px solid rgba(255, 255, 255, 0.4)' }}>₩ {bet.amount.toLocaleString()}</td>
                                        <td style={{ textAlign: 'right', paddingRight: '5px' }}>₩ {bet.profit.toLocaleString()}</td>
                                    </tr>
                                ))}
                                <tr style={{ borderBottom: '1px solid rgba(255, 255, 255, 0.4)', backgroundColor: 'rgba(0, 0, 0, 0.3)', textAlign: 'center', fontSize: '14px', color: 'white' }}>
                                    <td style={{ textAlign: 'left', paddingLeft: '5px' }}>합계</td><td style={{ textAlign: 'right', paddingRight: '5px', borderLeft: '1px solid rgba(255, 255, 255, 0.4)', borderRight: '1px solid rgba(255, 255, 255, 0.4)' }}>₩ {selectedGame.betSum.toLocaleString()}</td><td style={{ textAlign: 'right', paddingRight: '5px' }}>₩ {selectedGame.profitSum.toLocaleString()}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div style={{ position: 'absolute', width: '100%', height: '50px', bottom: 0, textAlign: 'center', borderTop: '1px solid rgba(255, 255, 255, 0.4)', backgroundColor: 'rgba(0, 0, 0, 0.3)' }}>
                            <button style={{ marginTop: '5px', height: '40px', width: '300px', borderRadius: '45px', border: '1px solid rgba(255, 255, 255, 0.4)', backgroundColor: 'rgba(0, 0, 0, 0.3)', color: 'white', fontSize: '15px' }} onClick={() => setSelectedGame(undefined)}>게임 기록으로 돌아가기</button>
                        </div>
                    </div>
                ) : (
                    <BetHistoryTable>
                        <thead>
                            <tr>
                                <th style={{ textAlign: 'left' }}>날짜</th>
                                <th style={{ textAlign: 'left' }}>게임</th>
                                <th style={{ textAlign: 'right' }}>배팅</th>
                                <th style={{ textAlign: 'right' }}>승리/패배</th>
                            </tr>
                        </thead>
                        <tbody>
                            {history.map((day) => (
                                <>
                                    <BetHistoryTableDateRow>
                                        <td colSpan={2}>-{day.day}</td>
                                        <td style={{ textAlign: 'right' }}>₩ {day.betSum.toLocaleString()}</td>
                                        <td style={{ textAlign: 'right' }}>₩ {(day.profitSum - day.betSum).toLocaleString()}</td>
                                    </BetHistoryTableDateRow>
                                    {day.games.map((game) => (
                                        <tr onClick={() => setSelectedGame(game)} style={{ cursor: 'pointer' }}>
                                            <td style={{ textAlign: 'left' }}>{game.createdFormatted}</td>
                                            <td style={{ textAlign: 'left' }}>스피드파워볼 {game.autoBet ? '(오토베팅)' : ''}</td>
                                            <td style={{ textAlign: 'right' }}>₩ {game.betSum.toLocaleString()}</td>
                                            <td style={{ textAlign: 'right' }}>₩ {(game.profitSum - game.betSum).toLocaleString()}</td>
                                        </tr>
                                    ))}
                                </>
                            ))}
                        </tbody>
                    </BetHistoryTable>
                )}
            </BetHistoryContent>
        </BetHistoryWrapper>
    );
}