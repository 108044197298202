import OvenPlayer, {OvenPlayerConfig, OvenPlayerInstance} from 'ovenplayer';
import React, {RefObject} from "react";
import { Component } from "react";

export class OvenPlayerWrapper extends Component<OvenPlayerConfig, {}> {
    private playerRef: RefObject<HTMLDivElement>;
    private player: OvenPlayerInstance | undefined;

    constructor(props: OvenPlayerConfig) {
        super(props);
        this.playerRef = React.createRef();
    }

    componentDidMount() {
        if (this.playerRef.current) {
            const playerElement = document.createElement('div');
            this.playerRef.current.append(playerElement);
            this.player = OvenPlayer.create(playerElement, {...this.props});
        }
    }

    componentDidUpdate(prevProps: Readonly<OvenPlayerConfig>, prevState: Readonly<{}>, snapshot?: any) {
        if (this.player) {
            this.player.setMute(this.props.mute ?? true);
        }
    }

    render() {
        return <div ref={this.playerRef}></div>;
    }
}