import {FunctionComponent, ReactElement, useEffect, useState} from "react";
import styled from "styled-components";

const Item = styled.div<{ type: 'RED' | 'BLUE' }>`
    width: 20px;
    height: 20px;
    line-height: 20px;
    border-radius: 50%;
    font-weight: 600;
    font-size: 11px;
  
    color: white;
    text-align: center;

    ${props => props.type === 'RED' ? `
        background-color: rgb(188, 49, 36);    
    ` : `
        background-color: rgb(68, 126, 179);
    `}
`;

export const RoadItem: FunctionComponent<{
    history: number[],
    type: 'ODDEVEN' | 'UNOVER_POWER' | 'UNOVER_NORMAL';
    rowCount: number;
    colCount: number;
}> = (props) => {
    const [pattern, setPattern] = useState<{
        pattern: 0 | 1;
        number: number[];
    }[]>([]);
    const [table, setTable] = useState<(ReactElement | undefined)[][]>([]);

    useEffect(() => {
        const getCurrentItem = (item: number): (0 | 1) => {
            if (props.type === 'ODDEVEN') {
                return item % 2 === 0 ? 0 : 1;
            } else if (props.type === 'UNOVER_POWER') {
                return item > 4.5 ? 0 : 1;
            } else if (props.type === 'UNOVER_NORMAL') {
                return item > 72.5 ? 0 : 1;
            }
            return 0;
        }

        const newPattern: {
            pattern: 0 | 1;
            number: number[];
        }[] = [];
        for (const item of props.history) {
            if (item === null) continue;

            const currentItem = getCurrentItem(item);
            if (newPattern.length === 0) {
                newPattern.push({
                    pattern: currentItem,
                    number: [item],
                });
                continue;
            }
            if (newPattern[newPattern.length - 1].pattern === currentItem) {
                newPattern[newPattern.length - 1].number.push(item);
            } else {
                newPattern.push({
                    pattern: currentItem,
                    number: [item],
                });
            }
        }
        setPattern(newPattern.slice(-(props.rowCount - 3)));
    }, [props.history, props.type]);

    useEffect(() => {
        const newTable: (ReactElement | undefined)[][] = [];
        let currentX = 0, currentY = 0;

        for (let i = 0; i < 20; i++) {
            const row = [];
            for (let j = 0; j < props.rowCount; j++) {
                row.push(undefined);
            }
            newTable.push(row);
        }

        for (const row of pattern) {
            for (const column of row.number) {
                if (newTable[currentX][currentY] === undefined && currentX < (props.colCount - 1)) {
                    newTable[currentX][currentY] = <Item type={row.pattern === 0 ? 'RED' : 'BLUE'}>{column}</Item>;
                    currentX++;
                } else {
                    let findingY = currentY;
                    while (newTable[currentX][findingY] !== undefined) {
                        findingY++;
                    }
                    newTable[currentX][findingY] = <Item type={row.pattern === 0 ? 'RED' : 'BLUE'}>{column}</Item>;
                }
            }
            currentX = 0;
            currentY++;
        }
        setTable(newTable);
    }, [pattern]);

    return (
        <div>
            <table>
                <tbody>
                {table.map((row) => (
                    <tr>
                        {row.map((column) => (
                            <td>{column}</td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
}