import {FunctionComponent, useEffect} from "react";
import styled from "styled-components";

import './Timer.css';
import {BrowserView, isMobile, MobileView} from "react-device-detect";

const WaitNextRoundDiv = styled.div<{ isAllMarket?: boolean }>`
    position: absolute;
    background: radial-gradient(rgba(0,0,0,.45) 15%,rgba(0,0,0,.35) 30%,transparent 60%);
    width: 494px;
    height: 146px;
    text-align: center;
    line-height: 146px;
    color: white;
    left: 50%;
    ${props => props.isAllMarket ? `
        bottom: 400px;
    ` : `
        bottom: 240px;
    `}
    transition-duration: 300ms;
    font-size: 20px;
    transform: translateX(-50%);
`;

const TimerDiv = styled.div<{ isAllMarket?: boolean }>`
    position: absolute;
    width: 146px;
    height: 146px;
    text-align: center;
    color: white;
    left: 50%;
    ${props => props.isAllMarket ? `
        bottom: 500px;
    ` : `
        bottom: 320px;
    `}
    font-weight: 600;
    font-size: 40px;
    transition-duration: 300ms;
    transform: translateX(-50%);
`;

const TimerBackgroundDiv = styled.div`
    position: absolute;
    background: radial-gradient(rgba(0,0,0,.8) 15%,rgba(0,0,0,.6) 30%,transparent 60%);
    width: 146px;
    height: 146px;
    line-height: 146px;
    text-align: center;
`;

const TimerTimeDiv = styled.div`
    position: absolute;
    width: 146px;
    height: 146px;
    line-height: 136px;
    text-align: center;
`;

export const Timer: FunctionComponent<{ leftTime: number, isAllMarket: boolean }> = (props) => {
    let totalTime = 40;
    let remainingTime = totalTime;
    let timerInterval: number | NodeJS.Timer;

    function startTimer() {
        const circle = document.getElementById('timer-circle');

        if (!circle) return;

        clearInterval(timerInterval);
        let timeLeft = remainingTime;
        timerInterval = setInterval(() => {
            timeLeft--;
            remainingTime = timeLeft;
            const progress = (totalTime - timeLeft) / totalTime; // 565
            circle.style.strokeDashoffset = `${(isMobile ? -163 : -251) * progress}`;

            if (timeLeft <= 0) {
                clearInterval(timerInterval);
            }
        }, 1000);
    }

    useEffect(() => {
        remainingTime = props.leftTime;
        startTimer();
    }, [props.leftTime]);

    return props.leftTime < 0
        ? (
            <>
                <BrowserView><WaitNextRoundDiv isAllMarket={props.isAllMarket}>다음 게임 기다리기</WaitNextRoundDiv></BrowserView>
                <MobileView><WaitNextRoundDiv style={{ bottom: 'auto', top: 0, marginTop: '275px', background: 'none', fontSize: '16px' }}>다음 게임 기다리기</WaitNextRoundDiv></MobileView>
            </>
        )
        : (
            <>
                <BrowserView>
                    <TimerDiv isAllMarket={props.isAllMarket}>
                        <TimerBackgroundDiv>
                            <svg width="146" height="146">
                                <circle id="timer-circle" cx="75" cy="73" r="40"></circle>
                            </svg>
                        </TimerBackgroundDiv>
                        <TimerTimeDiv>{props.leftTime}</TimerTimeDiv>
                    </TimerDiv>
                </BrowserView>
                <MobileView>
                    <TimerDiv style={{ left: 'auto', transform: 'none', right: '5px', bottom: 'auto', top: '5px', width: '100px', height: '100px' }}>
                        <TimerBackgroundDiv style={{ width: '100px', height: '100px' }}>
                            <svg width="100" height="100">
                                <circle id="timer-circle" cx="52" cy="50" r="26"></circle>
                            </svg>
                        </TimerBackgroundDiv>
                        <TimerTimeDiv style={{ fontSize: '30px', width: '100px', height: '100px', lineHeight: '100px' }}>{props.leftTime}</TimerTimeDiv>
                    </TimerDiv>
                </MobileView>
            </>
        );
}