import {FunctionComponent} from "react";
import styled, {css, keyframes} from "styled-components";
import {BrowserView, MobileView} from "react-device-detect";

const VictoryTextAnimation = keyframes`
    0% {
        transform: scale(0);
    }
    90% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
`;

const VictoryAlertWrapperDiv = styled.div`
    width: 600px;
    height: 130px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;
const MobileVictoryAlertWrapperDiv = styled.div`
    width: 600px;
    height: 130px;
    position: absolute;
    left: 50%;
    margin-top: -300px;
    transform: translateX(-50%) scale(0.7);
    pointer-events: none;
`;
const VictoryAlertBackgroundDiv = styled.div<{ hidden: boolean }>`
    width: 800px;
    height: 800px;
  
    background: rgb(0,0,0);
    background: radial-gradient(circle, rgba(0, 0, 0, 1) 0%, rgba(0,0,0, 0.8) 10%, rgba(0, 0, 0, 0.5) 45%, rgba(255,255,255,0) 50%);
    background-size: cover;
    background-repeat: no-repeat;
  
    margin-left: -100px;
    margin-top: -550px;

    transition-duration: 300ms;
    opacity: 1;
    transition-property: opacity;
  
    ${props => props.hidden && css`
        opacity: 0;
    `}
`;
const VictoryAlertTopBorderDiv = styled.div<{ hidden: boolean }>`
    height: 2px;
    margin: 0 auto;
    background: radial-gradient(circle, rgb(250, 224, 128) 0%, rgb(127, 119, 95) 60%, transparent 100%);
    transition-duration: 300ms;
    transition-property: width;
  
    ${props => props.hidden ? css`
        width: 0;
    ` : css`
        width: 490px;
    `}
`;
const VictoryAlertHeader = styled.div<{ hidden: boolean }>`
    color: white;
    position: absolute;
    width: 100%;
    text-align: center;
    margin-top: -235px;
    font-size: 18px;
  
    transition-duration: 300ms;
    transition-property: opacity;
  
    ${props => props.hidden ? css`
        opacity: 0;
    ` : css`
        opacity: 1;
    `}
`;
const VictoryAlertContent = styled.div<{ hidden: boolean }>`
    background: linear-gradient(0deg, rgb(250, 224, 128) 50%, white 100%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    font-weight: 600;
    position: absolute;
    width: 100%;
    text-align: center;
    margin-top: -210px;
    font-size: 33px;
  
    ${props => props.hidden ? css`
        animation: ${VictoryTextAnimation} 500ms ease-in;
    ` : css`
        animation: ${VictoryTextAnimation} 500ms ease-out;    
    `}
`;

export const VictoryAlert: FunctionComponent<{ price: number, hidden: boolean }> = (props) => {
    return (
        <>
            <BrowserView>
                <VictoryAlertWrapperDiv>
                    <VictoryAlertTopBorderDiv hidden={props.hidden} />
                    <div style={{ overflow: "hidden" }}><VictoryAlertBackgroundDiv hidden={props.hidden} /></div>
                    <VictoryAlertHeader hidden={props.hidden}>이기셨습니다</VictoryAlertHeader>
                    <VictoryAlertContent hidden={props.hidden}>₩{props.price.toLocaleString()}</VictoryAlertContent>
                </VictoryAlertWrapperDiv>
            </BrowserView>
            <MobileView>
                <MobileVictoryAlertWrapperDiv>
                    <VictoryAlertTopBorderDiv hidden={props.hidden} />
                    <div style={{ overflow: "hidden" }}><VictoryAlertBackgroundDiv hidden={props.hidden} /></div>
                    <VictoryAlertHeader hidden={props.hidden}>이기셨습니다</VictoryAlertHeader>
                    <VictoryAlertContent hidden={props.hidden}>₩{props.price.toLocaleString()}</VictoryAlertContent>
                </MobileVictoryAlertWrapperDiv>
            </MobileView>
        </>
    );
}