import React, {useEffect, useRef, useState} from 'react';
import {BrowserView, MobileView} from 'react-device-detect';
import {OvenPlayerWrapper} from "./components/OvenPlayerWrapper";
import styled from "styled-components";
import {Header} from "./components/Header";
import {BetPanel} from "./components/BetPanel";
import {Balance} from "./components/Balance";
import {BetAmount} from "./components/BetAmount";
import {Chips} from "./components/Chips";
import {CurrentBalls} from "./components/CurrentBalls";
import {ButtonArea} from "./components/ButtonArea";
import {Logo} from "./components/Logo";
import {Timer} from "./components/Timer";
import {BetHistory} from "./components/BetHistory";
import swal from 'sweetalert2';
import {Road} from "./components/Road";
import {VictoryAlert} from "./components/VictoryAlert";
import {BottomButton} from "./components/BottomButton";
import {Stats} from "./components/Stats";

import sound_placeChip from './sounds/placeChip.webm';
import sound_repeatDouble from './sounds/repeatDouble.webm';
import sound_undo from './sounds/undo.webm';
import sound_selectChip from './sounds/selectChip.webm';
import sound_win from './sounds/Evo_Powerball_Win.webm';
import sound_nowin from './sounds/Evo_Powerball_NoWin.webm';

import useSound from "use-sound";
import {Alert} from "./components/Alert";

export const _API_URL = 'https://api.sp-evogames.com';
export const _API_KEY = localStorage.getItem('token');

const LayerDiv = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    overflow: hidden;
`;

const CenteredDiv = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    max-height: 100%;
    transform: translate(-50%, -50%);
`;

const MobileVideoWrapper = styled.div<{ width: number }>`
    min-width: 100%;
    margin-left: 50%;
    transform: translateX(-50%);
    transition-duration: 300ms;
    width: ${(props) => props.width}px;
`;
const MobileVideoDimmer = styled.div`
    background: linear-gradient(180deg,transparent,rgba(2,33,58,.8) 46.04%,#02213a 50.89%,rgba(2,33,58,.8) 56.21%,transparent);
    position: absolute;
    height: 190px;
    width: 100%;
    margin-top: -95px;
    pointer-events: none;
`;

const _BET_ITEMS = [
    'RegularOdd', 'RegularEven',
    'PowerOdd', 'PowerEven',
    'RegularHigh', 'RegularMedium', 'RegularLow',
    'RegularOddUnder', 'RegularOddOver', 'RegularEvenUnder', 'RegularEvenOver',
    'RegularUnder', 'RegularOver',
    'PowerUnder', 'PowerOver',
    'RegularOddHigh', 'RegularOddMedium', 'RegularOddLow', 'RegularEvenHigh', 'RegularEvenMedium', 'RegularEvenLow',
    'PowerOddUnder', 'PowerOddOver', 'PowerEvenUnder', 'PowerEvenOver',
    'Powerball0', 'Powerball1', 'Powerball2', 'Powerball3', 'Powerball4', 'Powerball5', 'Powerball6', 'Powerball7', 'Powerball8', 'Powerball9',
    'RegularOddPowerOdd', 'RegularOddPowerEven', 'RegularEvenPowerOdd', 'RegularEvenPowerEven',
];

function useInterval(callback: Function, delay: number) {
  const savedCallback = useRef<Function>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

function App() {
  const [maxBet, setMaxBet] = useState<number>(1000);
  const [minBet, setMinBet] = useState<number>(1000);
  const [limits, setLimits] = useState<Record<string, number>>({});
  const [gameStage, setGameStage] = useState<'Betting' | 'Drawing' | 'AcceptingBets' | 'Resolving' | 'WaitingForGame'>('Betting');
  const [currentBalls, setCurrentBalls] = useState<number[]>([]);
  const [winBets, setWinBets] = useState<string[]>([]);
  const [nextGameDate, setNextGameDate] = useState<Date>(new Date());
  const [nextGameTime, setNextGameTime] = useState<number>(-1);
  const [selectedChip, setSelectedChip] = useState<number | undefined>(1000);
  const [betHistory, setBetHistory] = useState<{ betItem: string, amount: number }[][]>([]);
  const [betAmount, setBetAmount] = useState<Record<string, number>>({});
  const [winAmount, setWinAmount] = useState<Record<string, number>>({});
  const [winAmountIncludingBet, setWinAmountIncludingBet] = useState<Record<string, number>>({});
  const [isBetHistoryOpen, setIsBetHistoryOpen] = useState<boolean>(false);
  const [isMute, setIsMute] = useState<boolean>(true);
  const [balance, setBalance] = useState<number>(0);
  const [history, setHistory] = useState<{
    ballValues: number[];
    gameId: string;
    gameNumber: string;
    roundId: number;
    roundIds: number[];
  }[]>([]);
  const [betOdds, setBetOdds] = useState<Record<string, number>>({});
  const [marketType, setMarketType] = useState<'basic' | 'expended' | 'custom' | 'all'>('basic');
  const [showStat, setShowStat] = useState<boolean>(false);
  const [lastBet, setLastBet] = useState<Record<string, number>>({});

  const [playPlaceChip] = useSound(sound_placeChip);
  const [playRepeatDouble] = useSound(sound_repeatDouble);
  const [playUndo] = useSound(sound_undo);
  const [playSelectChip] = useSound(sound_selectChip);
  const [playWin] = useSound(sound_win);
  const [playNoWin] = useSound(sound_nowin);

  useEffect(() => {
    let ws: WebSocket;

    const getBalance = async () => {
      if (_API_KEY === 'GUEST') {
        setBalance(0);
        setMaxBet(20000000);
        setMinBet(1000);
        setLimits({});
        return;
      }
      const balanceRequest = await fetch(`${_API_URL}/game/balance?token=${_API_KEY}`);
      const balanceResponse = await balanceRequest.json();

      if (balanceResponse.success) {
        setBalance(balanceResponse.balance - balanceResponse.pendingBets);
        setMinBet(balanceResponse.minBet);
        setMaxBet(balanceResponse.maxBet);
        setLimits(balanceResponse.limits);
      } else {
        window.location.replace('/logout');
      }
    }

    const getOdds = async () => {
      if (_API_KEY === 'GUEST') {
        const _ODDS = [
          1.95, 1.95,
          1.95, 1.95,
          2.4, 2.3, 2.4,
          3.6, 3.6, 3.6, 3.6,
          1.95, 1.95,
          1.95, 1.95,
          4.3, 4.1, 4.3, 4.3, 4.1, 4.3,
          3.6, 3.6, 3.6, 3.6,
          8.7, 8.7, 8.7, 8.7, 8.7, 8.7, 8.7, 8.7, 8.7, 8.7,
          3.6, 3.6, 3.6, 3.6,
        ];

        setBetOdds(Object.fromEntries(_ODDS.map((odds, index) => ([_BET_ITEMS[index], odds]))));
        return;
      }
      const oddsRequest = await fetch(`${_API_URL}/game/odds?token=${_API_KEY}`);
      const oddsResponse = await oddsRequest.json();

      if (oddsResponse.success) {
        setBetOdds(oddsResponse.odds);
      }
    }

    const SyncBet = async () => {
      if (_API_KEY === 'GUEST') {
        return;
      }
      const request = await fetch(`${_API_URL}/bet/pending?token=${_API_KEY}`);
      const response = await request.json();

      if (response.success) {
        const newBetAmount: Record<string, number> = {};
        for (const betItem of _BET_ITEMS) {
          newBetAmount[betItem] = 0;
        }
        for (const bet of response.bets) {
          newBetAmount[bet.item] += parseFloat(bet.amount);
        }
        setBetAmount(newBetAmount);
        setBalance((curr) => curr - Object.values(newBetAmount).reduce((acc, curr) => acc + curr, 0));
      } else {
        swal.fire({
          title: '베팅 정보를 가져오는 중 오류가 발생했습니다.',
          icon: 'error',
        });
      }
    }

    const ValidateBetResult = async () => {
      if (_API_KEY === 'GUEST') {
        return;
      }
      const request = await fetch(`${_API_URL}/bet/current?token=${_API_KEY}`);
      const response = await request.json();

      if (response.success) {
        const activeBets = Object.fromEntries(response.bets.filter((bet: { item: string, amount: string, status: string }) => bet.status === 'Accepted').map((bet: { item: string, amount: string, status: string }) => ([bet.item, parseFloat(bet.amount)])));

        setBetAmount((curr) => {
          let betFailed = false;
          const newBetAmount = { ...curr };
          for (const betItem in curr) {
            if (curr[betItem] > 0 && curr[betItem] !== activeBets[betItem]) {
              newBetAmount[betItem] = activeBets[betItem] || 0;
              betFailed = true;
            }
          }

          if (betFailed) {
            swal.fire({
              title: '일부 베팅이 거절되었습니다.',
              icon: 'error',
            });
          }

          return newBetAmount;
        });
        
        await getBalance();
      } else {
        swal.fire({
          title: '베팅 정보를 가져오는 중 오류가 발생했습니다.',
          icon: 'error',
        });
      }
    }

    const connectWs = () => {
      ws = new WebSocket('wss://api.evostreams.net')
      ws.onmessage = (event) => {
        const rawData = event.data;
        const data = JSON.parse(rawData);

        if (data.type === 'ballState') {
          const balls = data.value.map((ball: { status: string, id: number, value?: number }) => ball.value !== undefined ? ball.value : ball.status === 'Shuffling' ? -1 : undefined).filter((ball: number | undefined) => ball !== undefined);

          if (balls.length === 6) {
            setWinBets((curr) => {
              const newWinBets = [...curr];
              const newWinItem = `Powerball${balls[5]}`;
              if (newWinBets.indexOf(newWinItem) === -1) {
                newWinBets.push(newWinItem);
              }
              return newWinBets;
            });
          }

          setCurrentBalls(balls);
        } else if (data.type === 'gameState' || data.type === 'gameStateRaw') {
          if (data.value.stage === 'Betting') {
            SyncBet().then();
            setBetHistory([]);
            setGameStage('Betting');
            setWinBets([]);
          } else if (data.value.stage === 'AcceptingBets') {
            setGameStage('AcceptingBets');
          } else if (data.value.stage === 'Resolving') {
            setGameStage('Resolving');
          } else if (data.value.stage === 'WaitingForGame') {
            setGameStage('WaitingForGame');
          } else {
            setGameStage('Drawing');
          }

          if (data.value.stage === 'DrawingPower') {
            ValidateBetResult().then();
          }

          if (data.value.stage !== 'AcceptingBets') {
            getBalance().then();
          }

          if (data.value.winBetSpots) {
            const customWinBetSpots = [];
            const winBetSpots = data.value.winBetSpots;

            if (winBetSpots.indexOf('PowerOdd') !== -1) {
              if (winBetSpots.indexOf('PowerUnder') !== -1) customWinBetSpots.push('PowerOddUnder');
              if (winBetSpots.indexOf('PowerOver') !== -1) customWinBetSpots.push('PowerOddOver');
            } else if (winBetSpots.indexOf('PowerEven') !== -1) {
              if (winBetSpots.indexOf('PowerUnder') !== -1) customWinBetSpots.push('PowerEvenUnder');
              if (winBetSpots.indexOf('PowerOver') !== -1) customWinBetSpots.push('PowerEvenOver');
            }

            if (winBetSpots.indexOf('RegularOdd') !== -1) {
              if (winBetSpots.indexOf('PowerOdd') !== -1) customWinBetSpots.push('RegularOddPowerOdd');
              if (winBetSpots.indexOf('PowerEven') !== -1) customWinBetSpots.push('RegularOddPowerEven');
            } else if (winBetSpots.indexOf('RegularEven') !== -1) {
              if (winBetSpots.indexOf('PowerOdd') !== -1) customWinBetSpots.push('RegularEvenPowerOdd');
              if (winBetSpots.indexOf('PowerEven') !== -1) customWinBetSpots.push('RegularEvenPowerEven');
            }

            setWinBets([...data.value.winBetSpots, ...customWinBetSpots]);
          }

          if (data.value.nextStageIn !== undefined && data.value.stage === 'Betting') {
            const emitDate = new Date(data.time);
            emitDate.setMilliseconds(emitDate.getMilliseconds() + data.value.nextStageIn);
            setNextGameDate(emitDate);
          }
        } else if (data.type === 'history') {
          setHistory(data.value);
        }
      }
      ws.onclose = () => {
        connectWs();
      }
    }
    connectWs();
    resetBetAmount();
    getBalance().then();
    getOdds().then();
  }, []);

  useEffect(() => {
    const newWinAmount: Record<string, number> = {};
    const newWinAmountIncludingBet: Record<string, number> = {};
    for (const betItem of _BET_ITEMS) {
      newWinAmount[betItem] = 0;
      newWinAmountIncludingBet[betItem] = 0;
    }
    for (const winBet of winBets) {
      if (betAmount[winBet]) {
        newWinAmount[winBet] = betAmount[winBet] * (betOdds[winBet] - 1);
        newWinAmountIncludingBet[winBet] = betAmount[winBet] * betOdds[winBet];
      }
    }
    setWinAmount(newWinAmount);
    setWinAmountIncludingBet(newWinAmountIncludingBet);
  }, [winBets]);

  useEffect(() => {
    if (!isMute) playSelectChip();
  }, [selectedChip]);
  useEffect(() => {
    const loadLastBet = async () => {
      if (_API_KEY === 'GUEST') {
        return;
      }

      const request = await fetch(`${_API_URL}/bet/last?token=${_API_KEY}`);
      const response = await request.json();

      if (response.success) {
        setLastBet(response.lastBet);
      } else {
        swal.fire({
          title: '마지막 베팅 정보를 가져오는 중 오류가 발생했습니다.',
          icon: 'error',
        });
      }
    }

    if (gameStage === "Resolving") {
      if (Object.values(winAmount).reduce((curr, sum) => curr + sum, 0) <= 0) {
        if (!isMute) playNoWin();
      } else {
        if (!isMute) playWin();
      }
    } else if (gameStage === 'Betting') {
      loadLastBet().then();
    }
  }, [gameStage]);

  useEffect(() => {
    if (gameStage === 'Drawing') {
      setBetAmount((current) => {
        const newBetAmount = { ...current };
        const availableBets = Object.fromEntries(Object.entries(current).filter((bet) => bet[1] > 0));
        let showAlert = false;
        for (const betItem in availableBets) {
          const amount = availableBets[betItem];

          if (amount < minBet) {
            showAlert = true;
            newBetAmount[betItem] = 0;
          }
        }
        if (showAlert) {
          swal.fire({
            title: '최소 베팅 금액보다 적은 베팅이 있어 일부 베팅이 거절되었습니다.',
            icon: 'error',
          });
        }
        return newBetAmount;
      });
    }
  }, [gameStage]);

  useInterval(() => {
    const now = new Date();
    const leftSeconds = Math.floor((nextGameDate.getTime() - now.getTime()) / 1000);

    setNextGameTime(leftSeconds);
  }, 100);

  const resetBetAmount = () => {
    const newBetAmount: Record<string, number> = {};
    for (const betItem of _BET_ITEMS) {
        newBetAmount[betItem] = 0;
    }
    setBetAmount(newBetAmount);
    setWinAmount(newBetAmount);
  }

  const [addFlyingChip, setAddFlyingChip] = useState<(destinationChipAmount: number, chipAmount: number, endTop: number, endLeft: number, reverse?: boolean, startTop?: number, startLeft?: number) => unknown>(() => () => {});
  const flyChip = (destinationChipAmount: number, chipAmount: number, endBet: string, reverse = false) => {
    const endElement = document.getElementById(`Bet_${endBet}`);
    if (!endElement) {
      return;
    }

    const elementWidth = endElement!.offsetWidth;
    const elementHeight = endElement!.offsetHeight;

    let endTop = 0, endLeft = 0, searchingElement = endElement;
    while (searchingElement!.id !== 'BetWrapper') {
      endTop += searchingElement!.offsetTop;
      endLeft += searchingElement!.offsetLeft;
      if (searchingElement!.id === 'BetItemWrapper') {
        endLeft -= searchingElement!.offsetWidth / 2;
      }

      searchingElement = searchingElement!.offsetParent as HTMLElement;
      if (!searchingElement) {
        return;
      }
    }

    addFlyingChip(destinationChipAmount, chipAmount, endTop + elementHeight - 22, endLeft + (elementWidth / 2) - 17, reverse);
  }

  const pendingBet = async (betItem: string, price: number) => {
    if (_API_KEY === 'GUEST') {
      swal.fire({
        title: '로그인 후 이용하세요.',
        icon: 'error',
      });
      return;
    }
    if (price === 0) return;
    const request = await fetch(`${_API_URL}/bet/pending`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userToken: _API_KEY,
        betItem,
        betAmount: price,
      }),
    });
    const response = await request.json();

    if (response.success) {
      return response.balance;
    } else {
      swal.fire({
        title: '베팅 중 오류가 발생했습니다.',
        icon: 'error',
      });
      const newBetAmount = { ...betAmount };
      newBetAmount[betItem] -= price;
      setBetAmount(newBetAmount);

      return;
    }
  }

  const cancelPendingBet = async (cancelItem: string, price: number) => {
    if (_API_KEY === 'GUEST') {
      swal.fire({
        title: '로그인 후 이용하세요.',
        icon: 'error',
      });
      return;
    }

    const request = await fetch(`${_API_URL}/bet/pending/cancel`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userToken: _API_KEY,
        cancelItem: cancelItem,
        cancelAmount: price,
      }),
    });
    const response = await request.json();

    if (response.success) {
      return response.balance;
    } else {
      swal.fire({
        title: '베팅 취소 중 오류가 발생했습니다.',
        icon: 'error',
      });
      const newBetAmount = { ...betAmount };
      newBetAmount[cancelItem] += price;
      setBetAmount(newBetAmount);

      return;
    }
  }

  const Bet = (betItem: string) => {
    if (selectedChip === undefined) {
      return;
    }
    if (selectedChip > balance) {
      swal.fire({
        title: '잔액이 부족합니다',
        icon: 'error',
      });
      return;
    }

    const calculatedMaxBet = Math.min(maxBet, limits[betItem] || maxBet);

    let realBetAmount: number;
    if (betAmount[betItem] + selectedChip > calculatedMaxBet) {
      realBetAmount = calculatedMaxBet - betAmount[betItem];
    } else {
      realBetAmount = selectedChip;
      flyChip(selectedChip, selectedChip, betItem);
    }
    setBalance((current) => Math.max(0, current - realBetAmount));
    if (!isMute) playPlaceChip();
    pendingBet(betItem, realBetAmount).then();

    if (realBetAmount > 0) {
      setBetHistory((current) => {
        const newBetHistory = [...current];
        newBetHistory.push([{ betItem, amount: selectedChip }]);
        return newBetHistory;
      });

      setBetAmount((current) => {
        const newBetAmount = { ...current };
        newBetAmount[betItem] += selectedChip;
        if (newBetAmount[betItem] > calculatedMaxBet) {
          newBetAmount[betItem] = calculatedMaxBet;
        }
        return newBetAmount;
      });
    }
  }

  const UndoBet = () => {
    if (betHistory.length === 0) {
      return;
    }

    const lastBets = betHistory[betHistory.length - 1];
    const newBetHistory = [...betHistory];
    newBetHistory.splice(betHistory.length - 1, 1);
    setBetHistory(newBetHistory);

    const newBetAmount = { ...betAmount };
    for (const lastBet of lastBets) {
      if (newBetAmount[lastBet.betItem] - lastBet.amount < 0) {
        setBalance((current) => current + newBetAmount[lastBet.betItem]);
        flyChip(selectedChip ?? 0, newBetAmount[lastBet.betItem], lastBet.betItem, true);
        cancelPendingBet(lastBet.betItem, newBetAmount[lastBet.betItem]).then();
        newBetAmount[lastBet.betItem] = 0;
      } else {
        setBalance((current) => current + lastBet.amount);
        newBetAmount[lastBet.betItem] -= lastBet.amount;
        cancelPendingBet(lastBet.betItem, lastBet.amount).then();
        flyChip(selectedChip ?? 0, lastBet.amount, lastBet.betItem, true);
      }
    }
    if (!isMute) playUndo();
    setBetAmount(newBetAmount);
  }

  const repeatBet = () => {
    const needAmount = Object.values(lastBet).reduce((curr, acc) => acc + curr, 0);
    if (balance < needAmount) {
      swal.fire({
          title: '잔액이 부족합니다',
          icon: 'error',
      });
      return;
    }

    setBalance((curr) => curr - needAmount);
    const newBetAmount = { ...betAmount };
    const newBetHistory = [];
    for (const betItem in lastBet) {
      const currentBetAmount = lastBet[betItem];
      pendingBet(betItem, currentBetAmount).then();
      flyChip(selectedChip ?? 0, currentBetAmount, betItem);
      newBetHistory.push({ betItem, amount: currentBetAmount });
      newBetAmount[betItem] = currentBetAmount;
    }

    if (!isMute) playRepeatDouble();
    setBetAmount(newBetAmount);
    setBetHistory([...betHistory, newBetHistory]);
  }

  const DoubleBet = () => {
    if (Object.values(betAmount).reduce((curr, acc) => acc + curr, 0) > balance) {
      swal.fire({
        title: '잔액이 부족합니다',
        icon: 'error',
      });
      return;
    }

    let betSum = 0;
    const newBetHistoryItem: { betItem: string, amount: number }[] = [];
    const newBetAmount = { ...betAmount };
    for (const betItem of _BET_ITEMS) {
      const calculatedMaxBet = Math.min(maxBet, limits[betItem] || maxBet);
      if (newBetAmount[betItem] * 2 > calculatedMaxBet) {
        betSum += calculatedMaxBet - newBetAmount[betItem];
        if (calculatedMaxBet - newBetAmount[betItem] > 0) {
          pendingBet(betItem, calculatedMaxBet - newBetAmount[betItem]).then();
          flyChip(selectedChip ?? 0, calculatedMaxBet - newBetAmount[betItem], betItem);
          newBetHistoryItem.push({
            betItem,
            amount: calculatedMaxBet - newBetAmount[betItem],
          });
        }
        newBetAmount[betItem] = calculatedMaxBet;
      } else {
        betSum += newBetAmount[betItem];
        if (newBetAmount[betItem] > 0) {
          pendingBet(betItem, newBetAmount[betItem]).then();
          flyChip(selectedChip ?? 0, newBetAmount[betItem], betItem);
          newBetHistoryItem.push({
            betItem,
            amount: newBetAmount[betItem],
          });
        }
        newBetAmount[betItem] *= 2;
      }
    }
    if (!isMute) playRepeatDouble();
    setBetAmount(newBetAmount);
    setBetHistory((curr) => [...curr, newBetHistoryItem]);

    setBalance((current) => current - betSum);
  }

  return (
    <div className="App">
      <BrowserView>
        <CenteredDiv>
          <OvenPlayerWrapper autoStart={true} autoFallback={true} mute={isMute} controls={false} sources={[{
            "type": "webrtc",
            "file": "wss://bamboo.evostreams.net:3334/evostreams/stream"
          }, {
            "type": "llhls",
            "file": "https://bamboo.evostreams.net:3334/evostreams/stream/llhls.m3u8"
          }]} />
          <LayerDiv style={{ pointerEvents: 'auto' }} />
          {/*<LayerDiv><Alert message={"베팅이 거절되었습니다."} subMessage={"고객 센터에 연락해 주세요."} /></LayerDiv>*/}
          <LayerDiv><Header minBet={minBet} maxBet={maxBet} /></LayerDiv>
          <LayerDiv><Logo /></LayerDiv>
          <LayerDiv><Balance balance={balance} /></LayerDiv>
          <LayerDiv><BetAmount betAmount={Object.values(betAmount).reduce((curr, acc) => acc + curr, 0)} /></LayerDiv>
          {(gameStage === 'Drawing' || gameStage === 'Resolving') ? <LayerDiv><CurrentBalls balls={currentBalls} /></LayerDiv> : null}
          <LayerDiv id={"BetWrapper"}><BetPanel
                        activeOdds={[...winBets]}
                        disabledOdds={[..._BET_ITEMS.filter((item) => parseFloat(betOdds[item]?.toString() ?? '0') === 0), ...((gameStage === 'Drawing' || nextGameTime < 0) ? [..._BET_ITEMS.filter((item) => winBets.indexOf(item) === -1)] : [])]}
                        disabled={gameStage === 'Drawing' || nextGameTime < 0}
                        betAmount={betAmount}
                        winAmount={winAmount}
                        onBet={(betItem) => Bet(betItem)}
                        odds={betOdds}
                        marketType={marketType}
                      /></LayerDiv>
          <LayerDiv id={"ChipWrapper"}><Chips balance={balance} disabled={gameStage === 'Drawing' || nextGameTime < 0} onLoad={(addFlyingChipFunc) => setAddFlyingChip(() => addFlyingChipFunc)} onChange={(chipAmount) => setSelectedChip(chipAmount)} onUndo={() => UndoBet()} isRetry={/*Object.values(betAmount).reduce((a, b) => a + b, 0) <= 0*/false} onDouble={/*() => Object.values(betAmount).reduce((a, b) => a + b, 0) > 0 ? DoubleBet() : repeatBet()*/() => DoubleBet()} /></LayerDiv>
          <LayerDiv><Timer isAllMarket={marketType === 'all'} leftTime={gameStage === 'Betting' ? nextGameTime : -1} /></LayerDiv>
          <LayerDiv><Road history={history} small={false} /></LayerDiv>
          <LayerDiv><ButtonArea isMute={isMute} onMute={() => setIsMute(!isMute)} isHistoryOpen={isBetHistoryOpen} onHistoryOpen={() => setIsBetHistoryOpen(!isBetHistoryOpen)} /></LayerDiv>
          <LayerDiv><BetHistory isOpen={isBetHistoryOpen} onClose={() => setIsBetHistoryOpen(false)} /></LayerDiv>
          <LayerDiv><VictoryAlert price={Object.values(winAmountIncludingBet).reduce((curr, sum) => curr + sum, 0)} hidden={gameStage !== 'Resolving' || Object.values(winAmount).reduce((curr, sum) => curr + sum, 0) === 0} /></LayerDiv>
          <LayerDiv><BottomButton onChange={(type) => setMarketType(type)} onStatOpen={() => setShowStat(!showStat)} isStatOpen={showStat} /></LayerDiv>
          <LayerDiv><Stats show={showStat} onClose={() => setShowStat(false)} history={history} /></LayerDiv>
          {/*<LayerDiv><Tooltip message={'로그인 후 이용하세요'} left={300} top={300} /></LayerDiv>*/}
        </CenteredDiv>
      </BrowserView>
      <MobileView>
        <div style={{ width: '100%', overflow: 'hidden' }}>
          <LayerDiv style={{ background: 'linear-gradient(45deg, rgba(30, 65, 87, 0) 0%, rgba(30, 65, 87, 0.8) 50%, rgba(30, 65, 87, 0) 100%), linear-gradient(45deg, rgb(19, 18, 35) -5%, rgb(30, 65, 87) 50%, rgb(19, 18, 35) 105%)' }} />
          <MobileVideoWrapper width={gameStage === 'Betting' ? 400 : 650}>
            <OvenPlayerWrapper autoStart={true} autoFallback={true} mute={isMute} controls={false} sources={[{
              "type": "webrtc",
              "file": "wss://bamboo.evostreams.net:3334/evostreams/stream"
            }, {
              "type": "llhls",
              "file": "https://bamboo.evostreams.net:3334/evostreams/stream/llhls.m3u8"
            }]} />
          </MobileVideoWrapper>
          <MobileVideoDimmer />
          <ButtonArea isMute={isMute} onMute={() => setIsMute(!isMute)} isHistoryOpen={isBetHistoryOpen} onHistoryOpen={() => setIsBetHistoryOpen(!isBetHistoryOpen)} />
          {(gameStage === 'Drawing' || gameStage === 'Resolving') ? <CurrentBalls balls={currentBalls} /> : null}
          {gameStage === 'Betting' ? <Road history={history} /> : null}
          <LayerDiv id={"BetWrapper"}><BetPanel
              activeOdds={[...winBets]}
              disabledOdds={[..._BET_ITEMS.filter((item) => parseFloat(betOdds[item]?.toString() ?? '0') === 0), ...((gameStage === 'Drawing' || nextGameTime < 0) ? [..._BET_ITEMS.filter((item) => winBets.indexOf(item) === -1)] : [])]}
              disabled={gameStage === 'Drawing' || nextGameTime < 0}
              betAmount={betAmount}
              winAmount={winAmount}
              onBet={(betItem) => Bet(betItem)}
              odds={betOdds}
              marketType={marketType}
          /></LayerDiv>
          <LayerDiv id={"ChipWrapper"}><Chips balance={balance} disabled={gameStage === 'Drawing' || nextGameTime < 0} onLoad={(addFlyingChipFunc) => setAddFlyingChip(() => addFlyingChipFunc)} onChange={(chipAmount) => setSelectedChip(chipAmount)} onUndo={() => UndoBet()} isRetry={/*Object.values(betAmount).reduce((a, b) => a + b, 0) <= 0*/false} onDouble={/*() => Object.values(betAmount).reduce((a, b) => a + b, 0) > 0 ? DoubleBet() : repeatBet()*/() => DoubleBet()} /></LayerDiv>
          <VictoryAlert price={Object.values(winAmountIncludingBet).reduce((curr, sum) => curr + sum, 0)} hidden={gameStage !== 'Resolving' || Object.values(winAmount).reduce((curr, sum) => curr + sum, 0) === 0} />
        </div>
        <LayerDiv><Balance balance={balance} /></LayerDiv>
        <LayerDiv><BetAmount betAmount={Object.values(betAmount).reduce((curr, acc) => acc + curr, 0)} /></LayerDiv>
        <LayerDiv><Header minBet={1000} maxBet={maxBet} /></LayerDiv>
        <LayerDiv><Timer isAllMarket={false} leftTime={gameStage === 'Betting' ? nextGameTime : -1} /></LayerDiv>
        <LayerDiv><BetHistory isOpen={isBetHistoryOpen} onClose={() => setIsBetHistoryOpen(false)} /></LayerDiv>
        <LayerDiv><BottomButton onChange={(type) => setMarketType(type)} onStatOpen={() => setShowStat(!showStat)} isStatOpen={showStat} /></LayerDiv>
        <LayerDiv><Stats show={showStat} onClose={() => setShowStat(false)} history={history} /></LayerDiv>
      </MobileView>
    </div>
  );
}

export default App;
