import {FunctionComponent} from "react";
import logo from './logo.gif';
import styled from "styled-components";

const LogoWrapperDiv = styled.div`
    position: absolute;
    width: 200px;
    bottom: 55px;
    left: 50px;
    
    & > img {
      width: 100%;
    }
`;

export const Logo: FunctionComponent<{}> = () => {
    return (
        <LogoWrapperDiv>
            <img src={logo} />
        </LogoWrapperDiv>
    )
}