import space from './space.svg';
import active from './active.svg';
import blue from './blue.svg';
import green from './green.svg';
import red from './red.svg';
import yellow from './yellow.svg';
import power from './power.svg';
import sum_disabled from './sum_disabled.svg';
import sum_intermediate from './sum_intermediate.svg';
import sum_final from './sum_final.svg';

import styled from "styled-components";
import {FunctionComponent} from "react";
import {BrowserView, MobileView} from "react-device-detect";

const MobileBallWrapper = styled.div`
    margin: 30px auto 0;
    width: 350px;
    height: 40px;
    text-align: center;
    transform-origin: top center;
    transform: scale(1.2);
`;
const BallWrapper = styled.div`
    position: absolute;
    width: 290px;
    right: 0;
    bottom: 250px;
`;

export const Ball = styled.div<{ type: 'none' | 'active' | 'blue' | 'yellow' | 'green' | 'red' | 'power' }>`
    ${props => props.type === 'none'
    ? `background-image: url(${space});` : props.type === 'active'
    ? `background-image: url(${active});` : props.type === 'blue'
    ? `background-image: url(${blue});` : props.type === 'yellow'
    ? `background-image: url(${yellow});` : props.type === 'green'
    ? `background-image: url(${green});` : props.type === 'red'
    ? `background-image: url(${red});` : props.type === 'power'
    ? `background-image: url(${power}); filter: drop-shadow(0 0 10px rgba(0,0,0,.5));` : ''}
  
    width: 34px;
    height: 34px;
    background-size: contain;
    display: inline-block;
  
    text-align: center;
    color: ${props => (['none', 'active', 'power'].indexOf(props.type) !== -1) ? '#ffffff' : '#3d4040'}${props => ['none', 'active'].indexOf(props.type) !== -1 ? '80' : ''};
    line-height: 35px;
    filter: drop-shadow(0 3px 6px rgba(0,0,0,.25));
    -webkit-text-stroke: 0.1px #fff;
    cursor: default;
    font-size: 16px;
    font-weight: 600;
    will-change: filter;
    vertical-align: middle;
    margin-right: 4px;
`;

export const SumWrapperDiv = styled.div<{ type: 'disabled' | 'intermediate' | 'final' }>`
    display: inline-block;
    vertical-align: middle;
    width: 42px;
    height: 34px;
    ${props => props.type === 'disabled'
    ? `background-image: url(${sum_disabled});` : props.type === 'intermediate'
    ? `background-image: url(${sum_intermediate});` : props.type === 'final'
    ? `background-image: url(${sum_final});` : ''}
    background-size: contain;
    margin-right: 19px;
`;
export const SumTextDiv = styled.div<{ type: 'disabled' | 'intermediate' | 'final' }>`
    height: 34px;
    line-height: 34px;
    font-weight: 600;
    display: inline-block;
    color: white;
    font-size: 16px;
    text-align: center;
    width: 100%;
    ${props => props.type === 'disabled' ? `
        color: hsla(0,0%,100%,.2);
    ` : ''}
    ${props => props.type === 'final' ? `
        -webkit-text-fill-color: transparent;
        background: linear-gradient(180deg,#feeeb7,#fbc026);
        -webkit-background-clip: text;
        background-clip: text;
    ` : ''}
`;

export const CurrentBalls: FunctionComponent<{ balls: number[] }> = (props) => {
    const ballType: ('none' | 'active' | 'blue' | 'yellow' | 'green' | 'red' | 'power')[] = [];
    const ballValue = [];
    for (let i = 0; i < 6; i++) {
        if (i === 5) {
            if (props.balls[i] === undefined) {
                ballType.push('none');
                ballValue.push('P');
            } else if (props.balls[i] === -1) {
                ballType.push('active');
                ballValue.push('P');
            } else {
                ballValue.push(props.balls[i]);
                ballType.push('power');
            }
        } else {
            if (props.balls[i] === undefined) {
                ballType.push('none');
                ballValue.push('');
            } else if (props.balls[i] === -1) {
                ballType.push('active');
                ballValue.push('');
            } else {
                ballValue.push(props.balls[i]);
                switch (props.balls[i] % 4) {
                    case 0:
                        ballType.push('blue');
                        break;
                    case 1:
                        ballType.push('red');
                        break;
                    case 2:
                        ballType.push('yellow');
                        break;
                    case 3:
                        ballType.push('green');
                        break;
                }
            }
        }
    }

    let sumWrapperType: 'disabled' | 'intermediate' | 'final' = 'disabled';
    if (props.balls.length >= 5 && props.balls.slice(0, 5).every((value) => value !== undefined && value !== -1)) {
        sumWrapperType = 'final';
    } else if (props.balls.length > 0) {
        sumWrapperType = 'intermediate';
    }

    let normalBallSum: string | number = '';
    if (props.balls.length > 0) {
        normalBallSum = props.balls.slice(0, 5).filter((ball) => ball !== -1).reduce((a, b) => a + b, 0);
    }

    return (
        <>
            <BrowserView>
                <BallWrapper>
                    <Ball type={ballType[0]}>{ballValue[0]}</Ball>
                    <Ball type={ballType[1]}>{ballValue[1]}</Ball>
                    <Ball type={ballType[2]}>{ballValue[2]}</Ball>
                    <Ball type={ballType[3]}>{ballValue[3]}</Ball>
                    <Ball type={ballType[4]}>{ballValue[4]}</Ball>
                    <SumWrapperDiv type={sumWrapperType}><SumTextDiv type={sumWrapperType}>{normalBallSum}</SumTextDiv></SumWrapperDiv>
                    <Ball type={ballType[5]}>{ballValue[5]}</Ball>
                </BallWrapper>
            </BrowserView>
            <MobileView>
                <MobileBallWrapper>
                    <Ball type={ballType[0]}>{ballValue[0]}</Ball>
                    <Ball type={ballType[1]}>{ballValue[1]}</Ball>
                    <Ball type={ballType[2]}>{ballValue[2]}</Ball>
                    <Ball type={ballType[3]}>{ballValue[3]}</Ball>
                    <Ball type={ballType[4]}>{ballValue[4]}</Ball>
                    <SumWrapperDiv type={sumWrapperType}><SumTextDiv type={sumWrapperType}>{normalBallSum}</SumTextDiv></SumWrapperDiv>
                    <Ball type={ballType[5]}>{ballValue[5]}</Ball>
                </MobileBallWrapper>
            </MobileView>
        </>
    );
}