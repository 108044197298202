import {FunctionComponent} from "react";
import styled from "styled-components";
import {BrowserView, MobileView} from "react-device-detect";

const BalanceElement = styled.div`
    position: absolute;
    bottom: 10px;
    left: 10px;
    width: 140px;
    height: 36px;
    border: 1px solid hsla(0,0%,100%,.4);
    background: rgba(26,26,26,.6);
    border-radius: 36px;
    box-sizing: border-box;
    padding-top: 1px;
    color: #ced0d1;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    line-height: 13px;
`;

const BalanceHeaderSpan = styled.span`
    font-size: 11px;
    color: white;
`;

const BalanceAmountSpan = styled.span`
    color: #fbdc01;
    font-size: 16px;
`;

const MobileBalanceElement = styled.div`
    position: absolute;
    bottom: 10px;
    left: 8px;
    font-weight: 600;
`;

export const Balance: FunctionComponent<{ balance: number }> = (props) => {
    return (
        <>
            <BrowserView>
                <BalanceElement>
                    <BalanceHeaderSpan>잔고</BalanceHeaderSpan><br />
                    <BalanceAmountSpan>₩ {props.balance.toLocaleString()}</BalanceAmountSpan>
                </BalanceElement>
            </BrowserView>
            <MobileView>
                <MobileBalanceElement>
                    <BalanceHeaderSpan style={{ fontSize: '14px' }}>잔고</BalanceHeaderSpan>&nbsp;
                    <BalanceAmountSpan style={{ fontSize: '14px' }}>₩ {props.balance.toLocaleString()}</BalanceAmountSpan>
                </MobileBalanceElement>
            </MobileView>
        </>
    )
}