import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: "https://5ecaceb7c6657488a112d97ca71c9705@o4506190717583360.ingest.sentry.io/4506190718697477",
    integrations: [
        new Sentry.BrowserTracing({
            tracePropagationTargets: ["localhost", /^https:\/\/api\.sp-evogames\.com\//],
        }),
        new Sentry.Replay({
            maskAllText: false,
        }),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <App />
);