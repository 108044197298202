import {FunctionComponent} from "react";
import styled from "styled-components";

import chipsImage from './chips.svg';
import {BrowserView, MobileView} from "react-device-detect";

const BetAmountElement = styled.div`
  position: absolute;
  bottom: 10px;
  left: 155px;
  width: 140px;
  height: 36px;
  border: 1px solid hsla(0, 0%, 100%, .4);
  border-radius: 36px;
  box-sizing: border-box;
  padding-top: 1px;
  color: #ced0d1;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  line-height: 13px;

  background: rgba(26, 26, 26, .6) url(${chipsImage}) no-repeat -6px 11px;
  background-size: contain;
`;

const BetAmountHeaderSpan = styled.span`
    font-size: 11px;
    color: white;
`;

const BetAmountAmountSpan = styled.span`
    color: #fbdc01;
    font-size: 16px;
`;

const MobileBetAmountElement = styled.div`
  position: absolute;
  bottom: 30px;
  left: 8px;
  font-weight: 600;
`;

export const BetAmount: FunctionComponent<{ betAmount: number }> = (props) => {
    return (
        <>
            <BrowserView>
                <BetAmountElement>
                    <BetAmountHeaderSpan>총 베팅금</BetAmountHeaderSpan><br />
                    <BetAmountAmountSpan>₩ {props.betAmount.toLocaleString()}</BetAmountAmountSpan>
                </BetAmountElement>
            </BrowserView>
            <MobileView>
                <MobileBetAmountElement>
                    <BetAmountHeaderSpan style={{ fontSize: '14px' }}>총 베팅금</BetAmountHeaderSpan>&nbsp;
                    <BetAmountAmountSpan style={{ fontSize: '14px' }}>₩ {props.betAmount.toLocaleString()}</BetAmountAmountSpan>
                </MobileBetAmountElement>
            </MobileView>
        </>
    )
}