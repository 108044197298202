import {FunctionComponent, useEffect, useState} from "react";
import { Button } from '../ButtonArea';
import {BrowserView, MobileView} from "react-device-detect";

import basicBetMarket from './basicBetMarket.svg';
import expendedBetMarket from './expendedBetMarket.svg';
import basicBetMarket_active from './basicBetMarket_active.svg';
import expendedBetMarket_active from './expendedBetMarket_active.svg';
import hangul from './hangul.svg';
import stat from './stat.svg';
import stat_active from './stat_active.svg';

import styled from "styled-components";

const BottomButtonWrapperDiv = styled.div`
  position: absolute;
  width: 630px;
  bottom: 10px;
  text-align: left;
  left: 50%;
  margin-left: 275px;
`;
const MobileBottomButtonWrapperDiv = styled.div`
    position: absolute;
    bottom: 55px;
    width: 380px;
    height: 34px;
    left: 3px;
`;

const ButtonWithText = styled.button<{ active: boolean }>`
  background-color: rgba(26, 26, 26, 0.6);
  border-radius: 25px;
  align-items: center;
  border: 0.0833rem solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  justify-content: center;
  text-align: center;
  pointer-events: auto;
  margin-left: 4px;
  position: relative;
  transition: background-color 0.5s ease 0s;
  padding: 7px 15px;
  cursor: pointer;
  color: white;

  ${props => props.active ? `
        background-color: hsla(0,0%,100%,.8);
        color: black;    
    ` : ''}
  font-size: 15px;

  & > img {
    width: 17px;
    height: 17px;
    vertical-align: middle;
  }
`;
const ButtonText = styled.div<{ show: boolean }>`
    display: inline-block;
    height: 20px;
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
    transition-duration: 600ms;
  
    ${props => props.show ? `
        max-width: 150px;
        margin-left: 10px;
    ` : `
        margin-left: 0;
        max-width: 0;
    `}
`;

export const BottomButton: FunctionComponent<{ onStatOpen: () => unknown, onChange: (marketType: 'basic' | 'expended' | 'custom' | 'all') => unknown, isStatOpen: boolean }> = (props) => {
    const [marketType, setMarketType] = useState<'basic' | 'expended' | 'custom' | 'all'>('basic');
    const [showText, setShowText] = useState<boolean>(false);

    useEffect(() => {
        props.onChange(marketType);
    }, [marketType]);

    return (
        <>
            <BrowserView>
                <BottomButtonWrapperDiv>
                    <ButtonWithText active={marketType === 'basic'} onClick={() => setMarketType('basic')}>
                        <img src={marketType === 'basic' ? basicBetMarket_active : basicBetMarket} alt="Basic Bet Market" />
                        <ButtonText show={showText}>메인 마켓</ButtonText>
                    </ButtonWithText>
                    <ButtonWithText active={marketType === 'expended'} onClick={() => setMarketType('expended')}>
                        <img src={marketType === 'expended' ? expendedBetMarket_active : expendedBetMarket} alt="Expended Bet Market" />
                        <ButtonText show={showText}>조합 마켓 A</ButtonText>
                    </ButtonWithText>
                    <ButtonWithText active={marketType === 'custom'} onClick={() => setMarketType('custom')}>
                        <img src={marketType === 'custom' ? expendedBetMarket_active : expendedBetMarket} alt="Custom Bet Market" />
                        <ButtonText show={showText}>조합 마켓 B</ButtonText>
                    </ButtonWithText>
                    <ButtonWithText active={marketType === 'all'} onClick={() => setMarketType('all')}>
                        <img src={marketType === 'all' ? expendedBetMarket_active : expendedBetMarket} alt="All Bet Market" />
                        <ButtonText show={showText}>모든 마켓 보기</ButtonText>
                    </ButtonWithText>
                    <Button onClick={() => setShowText(!showText)} style={{ width: '36px', height: '36px', verticalAlign: 'middle' }}>
                        <img src={showText ? expendedBetMarket : hangul} alt="Show text" />
                    </Button>
                    <Button onClick={() => props.onStatOpen()} style={{ width: '36px', height: '36px', verticalAlign: 'middle', float: 'right' }} active={props.isStatOpen}>
                        <img src={props.isStatOpen ? stat_active : stat} alt="Show stats" />
                    </Button>
                </BottomButtonWrapperDiv>
            </BrowserView>
            <MobileView>
                <MobileBottomButtonWrapperDiv>
                    <ButtonWithText active={marketType === 'basic'} onClick={() => setMarketType('basic')}>
                        <img src={marketType === 'basic' ? basicBetMarket_active : basicBetMarket} alt="Basic Bet Market" />
                        <ButtonText show={true}>메인 마켓</ButtonText>
                    </ButtonWithText>
                    <ButtonWithText active={marketType === 'expended'} onClick={() => setMarketType('expended')}>
                        <img src={marketType === 'expended' ? expendedBetMarket_active : expendedBetMarket} alt="Expended Bet Market" />
                        <ButtonText show={true}>조합 A</ButtonText>
                    </ButtonWithText>
                    <ButtonWithText active={marketType === 'custom'} onClick={() => setMarketType('custom')}>
                        <img src={marketType === 'custom' ? expendedBetMarket_active : expendedBetMarket} alt="Custom Bet Market" />
                        <ButtonText show={true}>조합 B</ButtonText>
                    </ButtonWithText>
                    <Button onClick={() => props.onStatOpen()} style={{ width: '36px', height: '36px', verticalAlign: 'middle', float: 'right' }} active={props.isStatOpen}>
                        <img src={props.isStatOpen ? stat_active : stat} alt="Show stats" />
                    </Button>
                </MobileBottomButtonWrapperDiv>
            </MobileView>
        </>
    );
}