import {FunctionComponent, useEffect} from "react";
import styled, {css, keyframes} from "styled-components";
import {BetChip} from "./BetChip";
import {BrowserView, MobileView} from "react-device-detect";

const BetWrapper = styled.div<{ disabled?: boolean, small?: boolean }>`
    position: absolute;
    bottom: 90px;
    left: 50%;
    ${props => props.small ? `
        max-width: 780px;
        min-width: 640px;
    ` : `
        max-width: 900px;
        min-width: 640px;    
    `}
    transform: translateX(-50%);
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  
    pointer-events: auto;
  
    transform-origin: bottom center;
    transition-duration: 300ms;
    ${props => props.disabled ? `
        bottom: 55px;
        transform: translateX(-50%) scale(0.9);
    ` : ''}
`;

const BetPanelHiddenAnimation = keyframes`
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      display: none;
      pointer-events: none;
    }
`;
const BetPanelShowAnimation = keyframes`
    0% {
      pointer-events: none;
      display: none;
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
`;
const BetPanelElement = styled.div<{ betHidden?: boolean }>`
    position: absolute;
    transition-duration: 300ms;
    
    ${props => props.betHidden ? css`
        animation: ${BetPanelHiddenAnimation} 300ms ease-in-out forwards 0.0001s;
    ` : css`
        opacity: 0;
        animation: ${BetPanelShowAnimation} 300ms ease-in-out forwards 0.0001s;
    `}
`;
const BetPanelHeader = styled.div`
    color: white;
    background: #56717c66;
    border-radius: 8px;
    font-weight: 400;
    height: 20px;
    margin-bottom: 0.25em;
    text-align: center;
    font-size: 12px;
    line-height: 20px;
    white-space: nowrap;
    word-break: keep-all;
    overflow: hidden;
`;
const LineDiv = styled.div`
    height: calc(100% - 23px);
    display: flex;
    flex-direction: row;
    gap: 3px;
`;
const BetBackgroundDiv = styled.div<{ type: string, active?: boolean, disabled?: boolean }>`
    cursor: pointer;
    flex: 1 1 0;
    padding: 2px;
    height: 100%;
    border-radius: 15px;
  
    box-sizing: border-box;
  
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    ${props =>
          props.active
                  ? `background: linear-gradient(180deg,#ffebaa,#fbdb4f 97%); cursor: auto;`
                  : props.type === 'odd'
                      ? `background: linear-gradient(180deg,#20647b 6%,#6ca8bf 46%,#3d6685 99%);`
                      : props.type === 'even'
                              ? `background: linear-gradient(180deg,#a72f38 6%,#f66a6a 57%,#b15759 98%);`
                              : `background: linear-gradient(180deg,#9e8567 6%,#c6a27a 61%,#ae9b7c 99%);`}
  
    transition: opacity .5s ease-in;
    ${props => props.disabled ? `opacity: 0.4;` : ''}
    
    ${props => (!props.disabled && !props.active) ? `
        &:active > div {
          box-shadow: inset 0 0 0.75em 0 rgba(0,0,0,.5);
        }    
    ` : 'cursor: auto;'}
`;
const BetButtonDiv = styled.div<{type: string}>`
    height: 100%;
    border-radius: 13px;
    text-align: center;
  
    ${props => 
            props.type === 'odd'
                    ? `background: radial-gradient(94.05% 63.11% at 49.58% 0.38%,rgba(161,209,227,.85) 0%,transparent 100%),linear-gradient(180deg,#47b0d1,#2a4560);`
                    : props.type === 'even'
                            ? `background: radial-gradient(88.01% 67.65% at 49.58% 0.38%,hsla(0,87%,73%,.85) 0%,transparent 100%),linear-gradient(180deg,#e83535,#8b151f);`
                            : `background: radial-gradient(94.05% 63.11% at 49.58% 0.38%,rgba(255,247,232,.85) 0%,transparent 81.22%),linear-gradient(180deg,#f7d7a8,#71592f);`} 
`;
const BetItemNameDiv = styled.div`
    color: white;
    font-size: 20px;
    filter: drop-shadow(0 2px 4px rgba(0,0,0,.6));
    height: 100%;
    letter-spacing: -2px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    white-space: nowrap;
    word-break: keep-all;
    transition-duration: 300ms;
`;
const BetItemOddsDiv = styled.div`
    margin-top: -18px;
    color: white;
    font-size: 13px;
    opacity: 0.7;
    white-space: nowrap;
`;
const BetItemRangeDiv = styled.div`
    color: white;
    font-size: 10px;
    bottom: 100%;
    margin-top: 6px;
    position: relative;
    white-space: nowrap;
    word-break: keep-all;
  
    & > div {
      display: inline-block;
      background: linear-gradient(0deg,hsla(31,37%,61%,.1),hsla(31,37%,61%,.1)),linear-gradient(180deg,rgba(77,77,77,.5),rgba(26,26,26,.5));
      border-radius: 10px;
      padding: 0 4px;
    }
`;

export const BetPanel: FunctionComponent<{ disabled: boolean, activeOdds: string[], disabledOdds: string[], betAmount: Record<string, number>, winAmount: Record<string, number>, onBet?: (betItem: string) => unknown, odds: Record<string, number>, marketType: 'basic' | 'expended' | 'custom' | 'all' }> = (props) => {
    return (
        <>
            <BrowserView>
                <BetWrapper id={"BetItemWrapper"} disabled={props.disabled} small={props.marketType === 'basic'} style={{ height: '205px' }}>
                    <BetPanelElement style={props.marketType === 'basic' ? { width: 'calc(50% - 3px)', height: 'calc(50% - 3px)', left: '0', top: '0', zIndex: '10' } : props.marketType === 'all' ? { width: 'calc(16% - 3px)', height: 'calc(50% - 3px)', left: '0', top: 'calc(-100% - 3px)', zIndex: '10' } : { width: 'calc(16% - 3px)', height: 'calc(50% - 3px)', left: '0', top: '0', zIndex: '10' }} betHidden={props.marketType !== 'basic' && props.marketType !== 'expended' && props.marketType !== 'all'}>
                        <BetPanelHeader>일반볼 홀짝</BetPanelHeader>
                        <LineDiv>
                            <BetBackgroundDiv id={"Bet_RegularOdd"} active={props.activeOdds.indexOf('RegularOdd') !== -1} disabled={props.disabledOdds.indexOf('RegularOdd') !== -1} type={"odd"}>
                                <BetButtonDiv type={"odd"} onClick={() => props.activeOdds.indexOf('RegularOdd') === -1 && props.disabledOdds.indexOf('RegularOdd') === -1 && props.onBet && props.onBet('RegularOdd')}>
                                    <BetItemNameDiv><div>홀</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['RegularOdd']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['RegularOdd'] + props.winAmount['RegularOdd']} />
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                            <BetBackgroundDiv id={"Bet_RegularEven"} active={props.activeOdds.indexOf('RegularEven') !== -1} disabled={props.disabledOdds.indexOf('RegularEven') !== -1} type={"even"}>
                                <BetButtonDiv type={"even"} onClick={() => props.activeOdds.indexOf('RegularEven') === -1 && props.disabledOdds.indexOf('RegularEven') === -1 && props.onBet && props.onBet('RegularEven')}>
                                    <BetItemNameDiv><div>짝</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['RegularEven']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['RegularEven'] + props.winAmount['RegularEven']} />
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                        </LineDiv>
                    </BetPanelElement>
                    <BetPanelElement style={props.marketType === 'basic' ? { width: 'calc(50% - 3px)', height: 'calc(50% - 3px)', left: 'calc(50% + 3px)', top: '0', zIndex: '10' } : props.marketType === 'all' ? { width: 'calc(16% - 3px)', height: 'calc(50% - 3px)', left: 'calc(16% + 3px)', top: 'calc(-100% - 3px)', zIndex: '10' } : { width: 'calc(16% - 3px)', height: 'calc(50% - 3px)', left: 'calc(16% + 3px)', top: '0', zIndex: '10' }} betHidden={props.marketType !== 'basic' && props.marketType !== 'expended' && props.marketType !== 'all'}>
                        <BetPanelHeader>파워볼 홀짝</BetPanelHeader>
                        <LineDiv>
                            <BetBackgroundDiv id={"Bet_PowerOdd"} active={props.activeOdds.indexOf('PowerOdd') !== -1} disabled={props.disabledOdds.indexOf('PowerOdd') !== -1} type={"odd"}>
                                <BetButtonDiv type={"odd"} onClick={() => props.activeOdds.indexOf('PowerOdd') === -1 && props.disabledOdds.indexOf('PowerOdd') === -1 && props.onBet && props.onBet('PowerOdd')}>
                                    <BetItemNameDiv><div>P홀</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['PowerOdd']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['PowerOdd'] + props.winAmount['PowerOdd']} />
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                            <BetBackgroundDiv id={"Bet_PowerEven"} active={props.activeOdds.indexOf('PowerEven') !== -1} disabled={props.disabledOdds.indexOf('PowerEven') !== -1} type={"even"}>
                                <BetButtonDiv type={"even"} onClick={() => props.activeOdds.indexOf('PowerEven') === -1 && props.disabledOdds.indexOf('PowerEven') === -1 && props.onBet && props.onBet('PowerEven')}>
                                    <BetItemNameDiv><div>P짝</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['PowerEven']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['PowerEven'] + props.winAmount['PowerEven']} />
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                        </LineDiv>
                    </BetPanelElement>

                    <BetPanelElement style={props.marketType === 'basic' ? { width: 'calc(50% - 3px)', height: 'calc(50% - 3px)', left: '0', top: 'calc(50% + 3px)', zIndex: '10' } : props.marketType === 'all' ? { width: 'calc(16% - 3px)', height: 'calc(50% - 3px)', left: '0', top: 'calc(-50% - 3px)', zIndex: '10' } : { width: 'calc(16% - 3px)', height: 'calc(50% - 3px)', left: '0', top: 'calc(50% + 3px)', zIndex: '10' }} betHidden={props.marketType !== 'basic' && props.marketType !== 'expended' && props.marketType !== 'all'}>
                        <BetPanelHeader>일반볼 언더오버</BetPanelHeader>
                        <LineDiv>
                            <BetBackgroundDiv id={"Bet_RegularUnder"} type={"odd"} active={props.activeOdds.indexOf('RegularUnder') !== -1} disabled={props.disabledOdds.indexOf('RegularUnder') !== -1}>
                                <BetButtonDiv type={"odd"} onClick={() => props.activeOdds.indexOf('RegularUnder') === -1 && props.disabledOdds.indexOf('RegularUnder') === -1 && props.onBet && props.onBet('RegularUnder')}>
                                    <BetItemNameDiv><div>언더</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['RegularUnder']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['RegularUnder'] + props.winAmount['RegularUnder']} />
                                    <BetItemRangeDiv><div>15-72</div></BetItemRangeDiv>
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                            <BetBackgroundDiv id={"Bet_RegularOver"} type={"even"} active={props.activeOdds.indexOf('RegularOver') !== -1} disabled={props.disabledOdds.indexOf('RegularOver') !== -1}>
                                <BetButtonDiv type={"even"} onClick={() => props.activeOdds.indexOf('RegularOver') === -1 && props.disabledOdds.indexOf('RegularOver') === -1 && props.onBet && props.onBet('RegularOver')}>
                                    <BetItemNameDiv><div>오버</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['RegularOver']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['RegularOver'] + props.winAmount['RegularOver']} />
                                    <BetItemRangeDiv><div>73-130</div></BetItemRangeDiv>
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                        </LineDiv>
                    </BetPanelElement>
                    <BetPanelElement style={props.marketType === 'basic' ? { width: 'calc(50% - 3px)', height: 'calc(50% - 3px)', left: 'calc(50% + 3px)', top: 'calc(50% + 3px)', zIndex: '10' } : props.marketType === 'all' ? { width: 'calc(16% - 3px)', height: 'calc(50% - 3px)', left: 'calc(16% + 3px)', top: 'calc(-50% - 3px)', zIndex: '10' } : { width: 'calc(16% - 3px)', height: 'calc(50% - 3px)', left: 'calc(16% + 3px)', top: 'calc(50% + 3px)', zIndex: '10' }} betHidden={props.marketType !== 'basic' && props.marketType !== 'expended' && props.marketType !== 'all'}>
                        <BetPanelHeader>파워볼 언더오버</BetPanelHeader>
                        <LineDiv>
                            <BetBackgroundDiv id={"Bet_PowerUnder"} type={"odd"} active={props.activeOdds.indexOf('PowerUnder') !== -1} disabled={props.disabledOdds.indexOf('PowerUnder') !== -1}>
                                <BetButtonDiv type={"odd"} onClick={() => props.activeOdds.indexOf('PowerUnder') === -1 && props.disabledOdds.indexOf('PowerUnder') === -1 && props.onBet && props.onBet('PowerUnder')}>
                                    <BetItemNameDiv><div>P언더</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['PowerUnder']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['PowerUnder'] + props.winAmount['PowerUnder']} />
                                    <BetItemRangeDiv><div>0-4</div></BetItemRangeDiv>
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                            <BetBackgroundDiv id={"Bet_PowerOver"} type={"even"} active={props.activeOdds.indexOf('PowerOver') !== -1} disabled={props.disabledOdds.indexOf('PowerOver') !== -1}>
                                <BetButtonDiv type={"even"} onClick={() => props.activeOdds.indexOf('PowerOver') === -1 && props.disabledOdds.indexOf('PowerOver') === -1 && props.onBet && props.onBet('PowerOver')}>
                                    <BetItemNameDiv><div>P오버</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['PowerOver']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['PowerOver'] + props.winAmount['PowerOver']} />
                                    <BetItemRangeDiv><div>5-9</div></BetItemRangeDiv>
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                        </LineDiv>
                    </BetPanelElement>

                    <BetPanelElement style={props.marketType === 'all' ? { width: 'calc(33% - 3px)', height: 'calc(50% - 3px)', left: 'calc(32% + 3px)', top: 'calc(-100% - 3px)' } : { width: 'calc(33% - 3px)', height: 'calc(50% - 3px)', left: 'calc(32% + 3px)', top: '0' }} betHidden={props.marketType !== 'expended' && props.marketType !== 'all'}>
                        <BetPanelHeader>일반볼 조합 (홀짝+언더오버)</BetPanelHeader>
                        <LineDiv>
                            <BetBackgroundDiv id={"Bet_RegularOddUnder"} type={"odd"} active={props.activeOdds.indexOf('RegularOddUnder') !== -1} disabled={props.disabledOdds.indexOf('RegularOddUnder') !== -1}>
                                <BetButtonDiv type={"odd"} onClick={() => props.activeOdds.indexOf('RegularOddUnder') === -1 && props.disabledOdds.indexOf('RegularOddUnder') === -1 && props.onBet && props.onBet('RegularOddUnder')}>
                                    <BetItemNameDiv><div>홀언더</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['RegularOddUnder']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['RegularOddUnder'] + props.winAmount['RegularOddUnder']} />
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                            <BetBackgroundDiv id={"Bet_RegularOddOver"} type={"odd"} active={props.activeOdds.indexOf('RegularOddOver') !== -1} disabled={props.disabledOdds.indexOf('RegularOddOver') !== -1}>
                                <BetButtonDiv type={"odd"} onClick={() => props.activeOdds.indexOf('RegularOddOver') === -1 && props.disabledOdds.indexOf('RegularOddOver') === -1 && props.onBet && props.onBet('RegularOddOver')}>
                                    <BetItemNameDiv><div>홀오버</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['RegularOddOver']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['RegularOddOver'] + props.winAmount['RegularOddOver']} />
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                            <BetBackgroundDiv id={"Bet_RegularEvenUnder"} type={"even"} active={props.activeOdds.indexOf('RegularEvenUnder') !== -1} disabled={props.disabledOdds.indexOf('RegularEvenUnder') !== -1}>
                                <BetButtonDiv type={"even"} onClick={() => props.activeOdds.indexOf('RegularEvenUnder') === -1 && props.disabledOdds.indexOf('RegularEvenUnder') === -1 && props.onBet && props.onBet('RegularEvenUnder')}>
                                    <BetItemNameDiv><div>짝언더</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['RegularEvenUnder']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['RegularEvenUnder'] + props.winAmount['RegularEvenUnder']} />
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                            <BetBackgroundDiv id={"Bet_RegularEvenOver"} type={"even"} active={props.activeOdds.indexOf('RegularEvenOver') !== -1} disabled={props.disabledOdds.indexOf('RegularEvenOver') !== -1}>
                                <BetButtonDiv type={"even"} onClick={() => props.activeOdds.indexOf('RegularEvenOver') === -1 && props.disabledOdds.indexOf('RegularEvenOver') === -1 && props.onBet && props.onBet('RegularEvenOver')}>
                                    <BetItemNameDiv><div>짝오버</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['RegularEvenOver']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['RegularEvenOver'] + props.winAmount['RegularEvenOver']} />
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                        </LineDiv>
                    </BetPanelElement>
                    <BetPanelElement style={props.marketType === 'all' ? { width: 'calc(35% - 3px)', height: 'calc(50% - 3px)', left: 'calc(65% + 3px)', top: 'calc(-100% - 3px)' } : { width: 'calc(35% - 3px)', height: 'calc(50% - 3px)', left: 'calc(65% + 3px)', top: '0' }} betHidden={props.marketType !== 'expended' && props.marketType !== 'all'}>
                        <BetPanelHeader>파워볼 조합 (홀짝+언더오버)</BetPanelHeader>
                        <LineDiv>
                            <BetBackgroundDiv id={"Bet_PowerOddUnder"} type={"odd"} active={props.activeOdds.indexOf('PowerOddUnder') !== -1} disabled={props.disabledOdds.indexOf('PowerOddUnder') !== -1}>
                                <BetButtonDiv type={"odd"} onClick={() => props.activeOdds.indexOf('PowerOddUnder') === -1 && props.disabledOdds.indexOf('PowerOddUnder') === -1 && props.onBet && props.onBet('PowerOddUnder')}>
                                    <BetItemNameDiv><div>홀언더</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['PowerOddUnder']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['PowerOddUnder'] + props.winAmount['PowerOddUnder']} />
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                            <BetBackgroundDiv id={"Bet_PowerOddOver"} type={"odd"} active={props.activeOdds.indexOf('PowerOddOver') !== -1} disabled={props.disabledOdds.indexOf('PowerOddOver') !== -1}>
                                <BetButtonDiv type={"odd"} onClick={() => props.activeOdds.indexOf('PowerOddOver') === -1 && props.disabledOdds.indexOf('PowerOddOver') === -1 && props.onBet && props.onBet('PowerOddOver')}>
                                    <BetItemNameDiv><div>홀오버</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['PowerOddOver']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['PowerOddOver'] + props.winAmount['PowerOddOver']} />
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                            <BetBackgroundDiv id={"Bet_PowerEvenUnder"} type={"even"} active={props.activeOdds.indexOf('PowerEvenUnder') !== -1} disabled={props.disabledOdds.indexOf('PowerEvenUnder') !== -1}>
                                <BetButtonDiv type={"even"} onClick={() => props.activeOdds.indexOf('PowerEvenUnder') === -1 && props.disabledOdds.indexOf('PowerEvenUnder') === -1 && props.onBet && props.onBet('PowerEvenUnder')}>
                                    <BetItemNameDiv><div>짝언더</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['PowerEvenUnder']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['PowerEvenUnder'] + props.winAmount['PowerEvenUnder']} />
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                            <BetBackgroundDiv id={"Bet_PowerEvenOver"} type={"even"} active={props.activeOdds.indexOf('PowerEvenOver') !== -1} disabled={props.disabledOdds.indexOf('PowerEvenOver') !== -1}>
                                <BetButtonDiv type={"even"} onClick={() => props.activeOdds.indexOf('PowerEvenOver') === -1 && props.disabledOdds.indexOf('PowerEvenOver') === -1 && props.onBet && props.onBet('PowerEvenOver')}>
                                    <BetItemNameDiv><div>짝오버</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['PowerEvenOver']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['PowerEvenOver'] + props.winAmount['PowerEvenOver']} />
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                        </LineDiv>
                    </BetPanelElement>

                    <BetPanelElement style={props.marketType === 'all' ? { width: 'calc(68% - 3px)', height: 'calc(50% - 3px)', left: 'calc(32% + 3px)', top: 'calc(-50% - 3px)' } : { width: 'calc(68% - 3px)', height: 'calc(50% - 3px)', left: 'calc(32% + 3px)', top: 'calc(50% + 3px)' }} betHidden={props.marketType !== 'expended' && props.marketType !== 'all'}>
                        <BetPanelHeader>일반볼 조합 (홀짝+대중소)</BetPanelHeader>
                        <LineDiv>
                            <BetBackgroundDiv id={"Bet_RegularOddHigh"} type={"odd"} active={props.activeOdds.indexOf('RegularOddHigh') !== -1} disabled={props.disabledOdds.indexOf('RegularOddHigh') !== -1}>
                                <BetButtonDiv type={"odd"} onClick={() => props.activeOdds.indexOf('RegularOddHigh') === -1 && props.disabledOdds.indexOf('RegularOddHigh') === -1 && props.onBet && props.onBet('RegularOddHigh')}>
                                    <BetItemNameDiv><div>홀대</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['RegularOddHigh']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['RegularOddHigh'] + props.winAmount['RegularOddHigh']} />
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                            <BetBackgroundDiv id={"Bet_RegularOddMedium"} type={"odd"} active={props.activeOdds.indexOf('RegularOddMedium') !== -1} disabled={props.disabledOdds.indexOf('RegularOddMedium') !== -1}>
                                <BetButtonDiv type={"odd"} onClick={() => props.activeOdds.indexOf('RegularOddMedium') === -1 && props.disabledOdds.indexOf('RegularOddMedium') === -1 && props.onBet && props.onBet('RegularOddMedium')}>
                                    <BetItemNameDiv><div>홀중</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['RegularOddMedium']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['RegularOddMedium'] + props.winAmount['RegularOddMedium']} />
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                            <BetBackgroundDiv id={"Bet_RegularOddLow"} type={"odd"} active={props.activeOdds.indexOf('RegularOddLow') !== -1} disabled={props.disabledOdds.indexOf('RegularOddLow') !== -1}>
                                <BetButtonDiv type={"odd"} onClick={() => props.activeOdds.indexOf('RegularOddLow') === -1 && props.disabledOdds.indexOf('RegularOddLow') === -1 && props.onBet && props.onBet('RegularOddLow')}>
                                    <BetItemNameDiv><div>홀소</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['RegularOddLow']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['RegularOddLow'] + props.winAmount['RegularOddLow']} />
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                            <BetBackgroundDiv id={"Bet_RegularEvenHigh"} type={"even"} active={props.activeOdds.indexOf('RegularEvenHigh') !== -1} disabled={props.disabledOdds.indexOf('RegularEvenHigh') !== -1}>
                                <BetButtonDiv type={"even"} onClick={() => props.activeOdds.indexOf('RegularEvenHigh') === -1 && props.disabledOdds.indexOf('RegularEvenHigh') === -1 && props.onBet && props.onBet('RegularEvenHigh')}>
                                    <BetItemNameDiv><div>짝대</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['RegularEvenHigh']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['RegularEvenHigh'] + props.winAmount['RegularEvenHigh']} />
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                            <BetBackgroundDiv id={"Bet_RegularEvenMedium"} type={"even"} active={props.activeOdds.indexOf('RegularEvenMedium') !== -1} disabled={props.disabledOdds.indexOf('RegularEvenMedium') !== -1}>
                                <BetButtonDiv type={"even"} onClick={() => props.activeOdds.indexOf('RegularEvenMedium') === -1 && props.disabledOdds.indexOf('RegularEvenMedium') === -1 && props.onBet && props.onBet('RegularEvenMedium')}>
                                    <BetItemNameDiv><div>짝중</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['RegularEvenMedium']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['RegularEvenMedium'] + props.winAmount['RegularEvenMedium']} />
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                            <BetBackgroundDiv id={"Bet_RegularEvenLow"} type={"even"} active={props.activeOdds.indexOf('RegularEvenLow') !== -1} disabled={props.disabledOdds.indexOf('RegularEvenLow') !== -1}>
                                <BetButtonDiv type={"even"} onClick={() => props.activeOdds.indexOf('RegularEvenLow') === -1 && props.disabledOdds.indexOf('RegularEvenLow') === -1 && props.onBet && props.onBet('RegularEvenLow')}>
                                    <BetItemNameDiv><div>짝소</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['RegularEvenLow']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['RegularEvenLow'] + props.winAmount['RegularEvenLow']} />
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                        </LineDiv>
                    </BetPanelElement>

                    <BetPanelElement style={{ width: 'calc(42% - 3px)', height: 'calc(50% - 3px)', left: '0', top: '0' }} betHidden={props.marketType !== 'custom' && props.marketType !== 'all'}>
                        <BetPanelHeader>일반볼 대/중/소</BetPanelHeader>
                        <LineDiv>
                            <BetBackgroundDiv id={"Bet_RegularHigh"} active={props.activeOdds.indexOf('RegularHigh') !== -1} disabled={props.disabledOdds.indexOf('RegularHigh') !== -1} type={"size"}>
                                <BetButtonDiv type={"size"} onClick={() => props.activeOdds.indexOf('RegularHigh') === -1 && props.disabledOdds.indexOf('RegularHigh') === -1 && props.onBet && props.onBet('RegularHigh')}>
                                    <BetItemNameDiv><div>대</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['RegularHigh']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['RegularHigh'] + props.winAmount['RegularHigh']} />
                                    <BetItemRangeDiv><div>81-130</div></BetItemRangeDiv>
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                            <BetBackgroundDiv id={"Bet_RegularMedium"} active={props.activeOdds.indexOf('RegularMedium') !== -1} disabled={props.disabledOdds.indexOf('RegularMedium') !== -1} type={"size"}>
                                <BetButtonDiv type={"size"} onClick={() => props.activeOdds.indexOf('RegularMedium') === -1 && props.disabledOdds.indexOf('RegularMedium') === -1 && props.onBet && props.onBet('RegularMedium')}>
                                    <BetItemNameDiv><div>중</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['RegularMedium']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['RegularMedium'] + props.winAmount['RegularMedium']} />
                                    <BetItemRangeDiv><div>65-80</div></BetItemRangeDiv>
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                            <BetBackgroundDiv id={"Bet_RegularLow"} active={props.activeOdds.indexOf('RegularLow') !== -1} disabled={props.disabledOdds.indexOf('RegularLow') !== -1} type={"size"}>
                                <BetButtonDiv type={"size"} onClick={() => props.activeOdds.indexOf('RegularLow') === -1 && props.disabledOdds.indexOf('RegularLow') === -1 && props.onBet && props.onBet('RegularLow')}>
                                    <BetItemNameDiv><div>소</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['RegularLow']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['RegularLow'] + props.winAmount['RegularLow']} />
                                    <BetItemRangeDiv><div>15-64</div></BetItemRangeDiv>
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                        </LineDiv>
                    </BetPanelElement>
                    <BetPanelElement style={{ width: 'calc(58% - 3px)', height: 'calc(50% - 3px)', left: 'calc(42% + 3px)', top: '0' }} betHidden={props.marketType !== 'custom' && props.marketType !== 'all'}>
                        <BetPanelHeader>일반볼+파워볼 조합 (홀짝)</BetPanelHeader>
                        <LineDiv>
                            <BetBackgroundDiv id={"Bet_RegularOddPowerOdd"} type={"odd"} active={props.activeOdds.indexOf('RegularOddPowerOdd') !== -1} disabled={props.disabledOdds.indexOf('RegularOddPowerOdd') !== -1}>
                                <BetButtonDiv type={"odd"} onClick={() => props.activeOdds.indexOf('RegularOddPowerOdd') === -1 && props.disabledOdds.indexOf('RegularOddPowerOdd') === -1 && props.onBet && props.onBet('RegularOddPowerOdd')}>
                                    <BetItemNameDiv><div>홀P홀</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['RegularOddPowerOdd']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['RegularOddPowerOdd'] + props.winAmount['RegularOddPowerOdd']} />
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                            <BetBackgroundDiv id={"Bet_RegularOddPowerEven"} type={"odd"} active={props.activeOdds.indexOf('RegularOddPowerEven') !== -1} disabled={props.disabledOdds.indexOf('RegularOddPowerEven') !== -1}>
                                <BetButtonDiv type={"odd"} onClick={() => props.activeOdds.indexOf('RegularOddPowerEven') === -1 && props.disabledOdds.indexOf('RegularOddPowerEven') === -1 && props.onBet && props.onBet('RegularOddPowerEven')}>
                                    <BetItemNameDiv><div>홀P짝</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['RegularOddPowerEven']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['RegularOddPowerEven'] + props.winAmount['RegularOddPowerEven']} />
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                            <BetBackgroundDiv id={"Bet_RegularEvenPowerOdd"} type={"even"} active={props.activeOdds.indexOf('RegularEvenPowerOdd') !== -1} disabled={props.disabledOdds.indexOf('RegularEvenPowerOdd') !== -1}>
                                <BetButtonDiv type={"even"} onClick={() => props.activeOdds.indexOf('RegularEvenPowerOdd') === -1 && props.disabledOdds.indexOf('RegularEvenPowerOdd') === -1 && props.onBet && props.onBet('RegularEvenPowerOdd')}>
                                    <BetItemNameDiv><div>짝P홀</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['RegularEvenPowerOdd']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['RegularEvenPowerOdd'] + props.winAmount['RegularEvenPowerOdd']} />
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                            <BetBackgroundDiv id={"Bet_RegularEvenPowerEven"} type={"even"} active={props.activeOdds.indexOf('RegularEvenPowerEven') !== -1} disabled={props.disabledOdds.indexOf('RegularEvenPowerEven') !== -1}>
                                <BetButtonDiv type={"even"} onClick={() => props.activeOdds.indexOf('RegularEvenPowerEven') === -1 && props.disabledOdds.indexOf('RegularEvenPowerEven') === -1 && props.onBet && props.onBet('RegularEvenPowerEven')}>
                                    <BetItemNameDiv><div>짝P짝</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['RegularEvenPowerEven']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['RegularEvenPowerEven'] + props.winAmount['RegularEvenPowerEven']} />
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                        </LineDiv>
                    </BetPanelElement>
                    <BetPanelElement style={{ width: '100%', height: 'calc(50% - 3px)', left: '0', top: 'calc(50% + 3px)' }} betHidden={props.marketType !== 'custom' && props.marketType !== 'all'}>
                        <BetPanelHeader>파워볼 정확한 숫자</BetPanelHeader>
                        <LineDiv>
                            <BetBackgroundDiv id={"Bet_Powerball0"} type={"even"} active={props.activeOdds.indexOf('Powerball0') !== -1} disabled={props.disabledOdds.indexOf('Powerball0') !== -1}>
                                <BetButtonDiv type={"even"} onClick={() => props.activeOdds.indexOf('Powerball0') === -1 && props.disabledOdds.indexOf('Powerball0') === -1 && props.onBet && props.onBet('Powerball0')}>
                                    <BetItemNameDiv><div>P0</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['Powerball0']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['Powerball0'] + props.winAmount['Powerball0']} />
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                            <BetBackgroundDiv id={"Bet_Powerball1"} type={"odd"} active={props.activeOdds.indexOf('Powerball1') !== -1} disabled={props.disabledOdds.indexOf('Powerball1') !== -1}>
                                <BetButtonDiv type={"odd"} onClick={() => props.activeOdds.indexOf('Powerball1') === -1 && props.disabledOdds.indexOf('Powerball1') === -1 && props.onBet && props.onBet('Powerball1')}>
                                    <BetItemNameDiv><div>P1</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['Powerball1']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['Powerball1'] + props.winAmount['Powerball1']} />
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                            <BetBackgroundDiv id={"Bet_Powerball2"} type={"even"} active={props.activeOdds.indexOf('Powerball2') !== -1} disabled={props.disabledOdds.indexOf('Powerball2') !== -1}>
                                <BetButtonDiv type={"even"} onClick={() => props.activeOdds.indexOf('Powerball2') === -1 && props.disabledOdds.indexOf('Powerball2') === -1 && props.onBet && props.onBet('Powerball2')}>
                                    <BetItemNameDiv><div>P2</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['Powerball2']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['Powerball2'] + props.winAmount['Powerball2']} />
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                            <BetBackgroundDiv id={"Bet_Powerball3"} type={"odd"} active={props.activeOdds.indexOf('Powerball3') !== -1} disabled={props.disabledOdds.indexOf('Powerball3') !== -1}>
                                <BetButtonDiv type={"odd"} onClick={() => props.activeOdds.indexOf('Powerball3') === -1 && props.disabledOdds.indexOf('Powerball3') === -1 && props.onBet && props.onBet('Powerball3')}>
                                    <BetItemNameDiv><div>P3</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['Powerball3']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['Powerball3'] + props.winAmount['Powerball3']} />
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                            <BetBackgroundDiv id={"Bet_Powerball4"} type={"even"} active={props.activeOdds.indexOf('Powerball4') !== -1} disabled={props.disabledOdds.indexOf('Powerball4') !== -1}>
                                <BetButtonDiv type={"even"} onClick={() => props.activeOdds.indexOf('Powerball4') === -1 && props.disabledOdds.indexOf('Powerball4') === -1 && props.onBet && props.onBet('Powerball4')}>
                                    <BetItemNameDiv><div>P4</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['Powerball4']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['Powerball4'] + props.winAmount['Powerball4']} />
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                            <BetBackgroundDiv id={"Bet_Powerball5"} type={"odd"} active={props.activeOdds.indexOf('Powerball5') !== -1} disabled={props.disabledOdds.indexOf('Powerball5') !== -1}>
                                <BetButtonDiv type={"odd"} onClick={() => props.activeOdds.indexOf('Powerball5') === -1 && props.disabledOdds.indexOf('Powerball5') === -1 && props.onBet && props.onBet('Powerball5')}>
                                    <BetItemNameDiv><div>P5</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['Powerball5']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['Powerball5'] + props.winAmount['Powerball5']} />
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                            <BetBackgroundDiv id={"Bet_Powerball6"} type={"even"} active={props.activeOdds.indexOf('Powerball6') !== -1} disabled={props.disabledOdds.indexOf('Powerball6') !== -1}>
                                <BetButtonDiv type={"even"} onClick={() => props.activeOdds.indexOf('Powerball6') === -1 && props.disabledOdds.indexOf('Powerball6') === -1 && props.onBet && props.onBet('Powerball6')}>
                                    <BetItemNameDiv><div>P6</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['Powerball6']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['Powerball6'] + props.winAmount['Powerball6']} />
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                            <BetBackgroundDiv id={"Bet_Powerball7"} type={"odd"} active={props.activeOdds.indexOf('Powerball7') !== -1} disabled={props.disabledOdds.indexOf('Powerball7') !== -1}>
                                <BetButtonDiv type={"odd"} onClick={() => props.activeOdds.indexOf('Powerball7') === -1 && props.disabledOdds.indexOf('Powerball7') === -1 && props.onBet && props.onBet('Powerball7')}>
                                    <BetItemNameDiv><div>P7</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['Powerball7']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['Powerball7'] + props.winAmount['Powerball7']} />
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                            <BetBackgroundDiv id={"Bet_Powerball8"} type={"even"} active={props.activeOdds.indexOf('Powerball8') !== -1} disabled={props.disabledOdds.indexOf('Powerball8') !== -1}>
                                <BetButtonDiv type={"even"} onClick={() => props.activeOdds.indexOf('Powerball8') === -1 && props.disabledOdds.indexOf('Powerball8') === -1 && props.onBet && props.onBet('Powerball8')}>
                                    <BetItemNameDiv><div>P8</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['Powerball8']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['Powerball8'] + props.winAmount['Powerball8']} />
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                            <BetBackgroundDiv id={"Bet_Powerball9"} type={"odd"} active={props.activeOdds.indexOf('Powerball9') !== -1} disabled={props.disabledOdds.indexOf('Powerball9') !== -1}>
                                <BetButtonDiv type={"odd"} onClick={() => props.activeOdds.indexOf('Powerball9') === -1 && props.disabledOdds.indexOf('Powerball9') === -1 && props.onBet && props.onBet('Powerball9')}>
                                    <BetItemNameDiv><div>P9</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['Powerball9']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['Powerball9'] + props.winAmount['Powerball9']} />
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                        </LineDiv>
                    </BetPanelElement>
                </BetWrapper>
            </BrowserView>
            <MobileView>
                <BetWrapper id={"BetItemWrapper"} disabled={props.disabled} style={{ width: 'calc(100% - 20px)', minWidth: 'calc(100% - 20px)', bottom: '150px', height: '260px' }}>
                    <BetPanelElement style={props.marketType === 'basic' ? { width: 'calc(50% - 3px)', height: 'calc(50% - 3px)', left: 0, top: 0 } : { width: 'calc(25% - 3px)', height: 'calc(33% - 3px)', left: 0, top: 0 }} betHidden={props.marketType !== 'basic' && props.marketType !== 'expended'}>
                        <BetPanelHeader>일반볼 홀짝</BetPanelHeader>
                        <LineDiv>
                            <BetBackgroundDiv id={"Bet_RegularOdd"} active={props.activeOdds.indexOf('RegularOdd') !== -1} disabled={props.disabledOdds.indexOf('RegularOdd') !== -1} type={"odd"} style={{ borderRadius: '9px' }}>
                                <BetButtonDiv type={"odd"} onClick={() => props.activeOdds.indexOf('RegularOdd') === -1 && props.disabledOdds.indexOf('RegularOdd') === -1 && props.onBet && props.onBet('RegularOdd')} style={{ borderRadius: '7px' }}>
                                    <BetItemNameDiv style={{ fontSize: props.marketType === 'basic' ? '25px' : '17px' }}><div>홀</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['RegularOdd']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['RegularOdd'] + props.winAmount['RegularOdd']} />
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                            <BetBackgroundDiv id={"Bet_RegularEven"} active={props.activeOdds.indexOf('RegularEven') !== -1} disabled={props.disabledOdds.indexOf('RegularEven') !== -1} type={"even"} style={{ borderRadius: '9px' }}>
                                <BetButtonDiv type={"even"} onClick={() => props.activeOdds.indexOf('RegularEven') === -1 && props.disabledOdds.indexOf('RegularEven') === -1 && props.onBet && props.onBet('RegularEven')} style={{ borderRadius: '7px' }}>
                                    <BetItemNameDiv style={{ fontSize: props.marketType === 'basic' ? '25px' : '17px' }}><div>짝</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['RegularEven']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['RegularEven'] + props.winAmount['RegularEven']} />
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                        </LineDiv>
                    </BetPanelElement>
                    <BetPanelElement style={props.marketType === 'basic' ? { width: 'calc(50% - 3px)', height: 'calc(50% - 3px)', left: 'calc(50% + 3px)', top: 0 } : { width: 'calc(25% - 3px)', height: 'calc(33% - 3px)', left: 'calc(25% + 3px)', top: 0 }} betHidden={props.marketType !== 'basic' && props.marketType !== 'expended'}>
                        <BetPanelHeader>일반볼 언더오버</BetPanelHeader>
                        <LineDiv>
                            <BetBackgroundDiv id={"Bet_RegularUnder"} type={"odd"} active={props.activeOdds.indexOf('RegularUnder') !== -1} disabled={props.disabledOdds.indexOf('RegularUnder') !== -1} style={{ borderRadius: '9px' }}>
                                <BetButtonDiv type={"odd"} onClick={() => props.activeOdds.indexOf('RegularUnder') === -1 && props.disabledOdds.indexOf('RegularUnder') === -1 && props.onBet && props.onBet('RegularUnder')} style={{ borderRadius: '7px' }}>
                                    <BetItemNameDiv style={{ fontSize: props.marketType === 'basic' ? '25px' : '17px' }}><div>언더</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['RegularUnder']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['RegularUnder'] + props.winAmount['RegularUnder']} />
                                    <BetItemRangeDiv><div>15-72</div></BetItemRangeDiv>
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                            <BetBackgroundDiv id={"Bet_RegularOver"} type={"even"} active={props.activeOdds.indexOf('RegularOver') !== -1} disabled={props.disabledOdds.indexOf('RegularOver') !== -1} style={{ borderRadius: '9px' }}>
                                <BetButtonDiv type={"even"} onClick={() => props.activeOdds.indexOf('RegularOver') === -1 && props.disabledOdds.indexOf('RegularOver') === -1 && props.onBet && props.onBet('RegularOver')} style={{ borderRadius: '7px' }}>
                                    <BetItemNameDiv style={{ fontSize: props.marketType === 'basic' ? '25px' : '17px' }}><div>오버</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['RegularOver']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['RegularOver'] + props.winAmount['RegularOver']} />
                                    <BetItemRangeDiv><div>73-130</div></BetItemRangeDiv>
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                        </LineDiv>
                    </BetPanelElement>
                    <BetPanelElement style={props.marketType === 'basic' ? { width: 'calc(50% - 3px)', height: 'calc(50% - 3px)', left: 0, top: 'calc(50% + 3px)' } : { width: 'calc(25% - 3px)', height: 'calc(33% - 3px)', left: 'calc(50% + 3px)', top: 0 }} betHidden={props.marketType !== 'basic' && props.marketType !== 'expended'}>
                        <BetPanelHeader>파워볼 홀짝</BetPanelHeader>
                        <LineDiv>
                            <BetBackgroundDiv id={"Bet_PowerOdd"} active={props.activeOdds.indexOf('PowerOdd') !== -1} disabled={props.disabledOdds.indexOf('PowerOdd') !== -1} type={"odd"} style={{ borderRadius: '9px' }}>
                                <BetButtonDiv type={"odd"} onClick={() => props.activeOdds.indexOf('PowerOdd') === -1 && props.disabledOdds.indexOf('PowerOdd') === -1 && props.onBet && props.onBet('PowerOdd')} style={{ borderRadius: '7px' }}>
                                    <BetItemNameDiv style={{ fontSize: props.marketType === 'basic' ? '25px' : '17px' }}><div>P홀</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['PowerOdd']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['PowerOdd'] + props.winAmount['PowerOdd']} />
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                            <BetBackgroundDiv id={"Bet_PowerEven"} active={props.activeOdds.indexOf('PowerEven') !== -1} disabled={props.disabledOdds.indexOf('PowerEven') !== -1} type={"even"} style={{ borderRadius: '9px' }}>
                                <BetButtonDiv type={"even"} onClick={() => props.activeOdds.indexOf('PowerEven') === -1 && props.disabledOdds.indexOf('PowerEven') === -1 && props.onBet && props.onBet('PowerEven')} style={{ borderRadius: '7px' }}>
                                    <BetItemNameDiv style={{ fontSize: props.marketType === 'basic' ? '25px' : '17px' }}><div>P짝</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['PowerEven']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['PowerEven'] + props.winAmount['PowerEven']} />
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                        </LineDiv>
                    </BetPanelElement>
                    <BetPanelElement style={props.marketType === 'basic' ? { width: 'calc(50% - 3px)', height: 'calc(50% - 3px)', left: 'calc(50% + 3px)', top: 'calc(50% + 3px)' } : { width: 'calc(25% - 3px)', height: 'calc(33% - 3px)', left: 'calc(75% + 3px)', top: 0 }} betHidden={props.marketType !== 'basic' && props.marketType !== 'expended'}>
                        <BetPanelHeader>파워볼 언더오버</BetPanelHeader>
                        <LineDiv>
                            <BetBackgroundDiv id={"Bet_PowerUnder"} type={"odd"} active={props.activeOdds.indexOf('PowerUnder') !== -1} disabled={props.disabledOdds.indexOf('PowerUnder') !== -1} style={{ borderRadius: '9px' }}>
                                <BetButtonDiv type={"odd"} onClick={() => props.activeOdds.indexOf('PowerUnder') === -1 && props.disabledOdds.indexOf('PowerUnder') === -1 && props.onBet && props.onBet('PowerUnder')} style={{ borderRadius: '7px' }}>
                                    <BetItemNameDiv style={{ fontSize: props.marketType === 'basic' ? '25px' : '16px' }}><div>P언더</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['PowerUnder']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['PowerUnder'] + props.winAmount['PowerUnder']} />
                                    <BetItemRangeDiv><div>0-4</div></BetItemRangeDiv>
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                            <BetBackgroundDiv id={"Bet_PowerOver"} type={"even"} active={props.activeOdds.indexOf('PowerOver') !== -1} disabled={props.disabledOdds.indexOf('PowerOver') !== -1} style={{ borderRadius: '9px' }}>
                                <BetButtonDiv type={"even"} onClick={() => props.activeOdds.indexOf('PowerOver') === -1 && props.disabledOdds.indexOf('PowerOver') === -1 && props.onBet && props.onBet('PowerOver')} style={{ borderRadius: '7px' }}>
                                    <BetItemNameDiv style={{ fontSize: props.marketType === 'basic' ? '25px' : '16px' }}><div>P오버</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['PowerOver']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['PowerOver'] + props.winAmount['PowerOver']} />
                                    <BetItemRangeDiv><div>5-9</div></BetItemRangeDiv>
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                        </LineDiv>
                    </BetPanelElement>

                    <BetPanelElement style={{ width: 'calc(50% - 3px)', height: 'calc(33% - 3px)', left: '0', top: 'calc(33% + 3px)' }} betHidden={props.marketType !== 'expended'}>
                        <BetPanelHeader>일반볼 조합 (홀짝+언더오버)</BetPanelHeader>
                        <LineDiv>
                            <BetBackgroundDiv id={"Bet_RegularOddUnder"} type={"odd"} active={props.activeOdds.indexOf('RegularOddUnder') !== -1} disabled={props.disabledOdds.indexOf('RegularOddUnder') !== -1} style={{ borderRadius: '9px' }}>
                                <BetButtonDiv type={"odd"} onClick={() => props.activeOdds.indexOf('RegularOddUnder') === -1 && props.disabledOdds.indexOf('RegularOddUnder') === -1 && props.onBet && props.onBet('RegularOddUnder')} style={{ borderRadius: '7px' }}>
                                    <BetItemNameDiv style={{ fontSize: '17px' }}><div>홀언더</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['RegularOddUnder']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['RegularOddUnder'] + props.winAmount['RegularOddUnder']} />
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                            <BetBackgroundDiv id={"Bet_RegularOddOver"} type={"odd"} active={props.activeOdds.indexOf('RegularOddOver') !== -1} disabled={props.disabledOdds.indexOf('RegularOddOver') !== -1} style={{ borderRadius: '9px' }}>
                                <BetButtonDiv type={"odd"} onClick={() => props.activeOdds.indexOf('RegularOddOver') === -1 && props.disabledOdds.indexOf('RegularOddOver') === -1 && props.onBet && props.onBet('RegularOddOver')} style={{ borderRadius: '7px' }}>
                                    <BetItemNameDiv style={{ fontSize: '17px' }}><div>홀오버</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['RegularOddOver']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['RegularOddOver'] + props.winAmount['RegularOddOver']} />
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                            <BetBackgroundDiv id={"Bet_RegularEvenUnder"} type={"even"} active={props.activeOdds.indexOf('RegularEvenUnder') !== -1} disabled={props.disabledOdds.indexOf('RegularEvenUnder') !== -1} style={{ borderRadius: '9px' }}>
                                <BetButtonDiv type={"even"} onClick={() => props.activeOdds.indexOf('RegularEvenUnder') === -1 && props.disabledOdds.indexOf('RegularEvenUnder') === -1 && props.onBet && props.onBet('RegularEvenUnder')} style={{ borderRadius: '7px' }}>
                                    <BetItemNameDiv style={{ fontSize: '17px' }}><div>짝언더</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['RegularEvenUnder']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['RegularEvenUnder'] + props.winAmount['RegularEvenUnder']} />
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                            <BetBackgroundDiv id={"Bet_RegularEvenOver"} type={"even"} active={props.activeOdds.indexOf('RegularEvenOver') !== -1} disabled={props.disabledOdds.indexOf('RegularEvenOver') !== -1} style={{ borderRadius: '9px' }}>
                                <BetButtonDiv type={"even"} onClick={() => props.activeOdds.indexOf('RegularEvenOver') === -1 && props.disabledOdds.indexOf('RegularEvenOver') === -1 && props.onBet && props.onBet('RegularEvenOver')} style={{ borderRadius: '7px' }}>
                                    <BetItemNameDiv style={{ fontSize: '17px' }}><div>짝오버</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['RegularEvenUnder']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['RegularEvenOver'] + props.winAmount['RegularEvenOver']} />
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                        </LineDiv>
                    </BetPanelElement>
                    <BetPanelElement style={{ width: 'calc(50% - 3px)', height: 'calc(33% - 3px)', left: 'calc(50% + 3px)', top: 'calc(33% + 3px)' }} betHidden={props.marketType !== 'expended'}>
                        <BetPanelHeader>파워볼 조합 (홀짝+언더오버)</BetPanelHeader>
                        <LineDiv>
                            <BetBackgroundDiv id={"Bet_PowerOddUnder"} type={"odd"} active={props.activeOdds.indexOf('PowerOddUnder') !== -1} disabled={props.disabledOdds.indexOf('PowerOddUnder') !== -1} style={{ borderRadius: '9px' }}>
                                <BetButtonDiv type={"odd"} onClick={() => props.activeOdds.indexOf('PowerOddUnder') === -1 && props.disabledOdds.indexOf('PowerOddUnder') === -1 && props.onBet && props.onBet('PowerOddUnder')} style={{ borderRadius: '7px' }}>
                                    <BetItemNameDiv style={{ fontSize: '17px' }}><div>홀언더</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['PowerOddUnder']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['PowerOddUnder'] + props.winAmount['PowerOddUnder']} />
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                            <BetBackgroundDiv id={"Bet_PowerOddOver"} type={"odd"} active={props.activeOdds.indexOf('PowerOddOver') !== -1} disabled={props.disabledOdds.indexOf('PowerOddOver') !== -1} style={{ borderRadius: '9px' }}>
                                <BetButtonDiv type={"odd"} onClick={() => props.activeOdds.indexOf('PowerOddOver') === -1 && props.disabledOdds.indexOf('PowerOddOver') === -1 && props.onBet && props.onBet('PowerOddOver')} style={{ borderRadius: '7px' }}>
                                    <BetItemNameDiv style={{ fontSize: '17px' }}><div>홀오버</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['PowerOddOver']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['PowerOddOver'] + props.winAmount['PowerOddOver']} />
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                            <BetBackgroundDiv id={"Bet_PowerEvenUnder"} type={"even"} active={props.activeOdds.indexOf('PowerEvenUnder') !== -1} disabled={props.disabledOdds.indexOf('PowerEvenUnder') !== -1} style={{ borderRadius: '9px' }}>
                                <BetButtonDiv type={"even"} onClick={() => props.activeOdds.indexOf('PowerEvenUnder') === -1 && props.disabledOdds.indexOf('PowerEvenUnder') === -1 && props.onBet && props.onBet('PowerEvenUnder')} style={{ borderRadius: '7px' }}>
                                    <BetItemNameDiv style={{ fontSize: '17px' }}><div>짝언더</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['PowerEvenUnder']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['PowerEvenUnder'] + props.winAmount['PowerEvenUnder']} />
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                            <BetBackgroundDiv id={"Bet_PowerEvenOver"} type={"even"} active={props.activeOdds.indexOf('PowerEvenOver') !== -1} disabled={props.disabledOdds.indexOf('PowerEvenOver') !== -1} style={{ borderRadius: '9px' }}>
                                <BetButtonDiv type={"even"} onClick={() => props.activeOdds.indexOf('PowerEvenOver') === -1 && props.disabledOdds.indexOf('PowerEvenOver') === -1 && props.onBet && props.onBet('PowerEvenOver')} style={{ borderRadius: '7px' }}>
                                    <BetItemNameDiv style={{ fontSize: '17px' }}><div>짝오버</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['PowerEvenOver']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['PowerEvenOver'] + props.winAmount['PowerEvenOver']} />
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                        </LineDiv>
                    </BetPanelElement>

                    <BetPanelElement style={{ width: '100%', height: 'calc(33% - 3px)', left: '0', top: 'calc(66% + 3px)' }} betHidden={props.marketType !== 'expended'}>
                        <BetPanelHeader>일반볼 조합 (홀짝+대중소)</BetPanelHeader>
                        <LineDiv>
                            <BetBackgroundDiv id={"Bet_RegularOddHigh"} type={"odd"} active={props.activeOdds.indexOf('RegularOddHigh') !== -1} disabled={props.disabledOdds.indexOf('RegularOddHigh') !== -1} style={{ borderRadius: '9px' }}>
                                <BetButtonDiv type={"odd"} onClick={() => props.activeOdds.indexOf('RegularOddHigh') === -1 && props.disabledOdds.indexOf('RegularOddHigh') === -1 && props.onBet && props.onBet('RegularOddHigh')} style={{ borderRadius: '7px' }}>
                                    <BetItemNameDiv style={{ fontSize: '17px' }}><div>홀대</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['RegularOddHigh']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['RegularOddHigh'] + props.winAmount['RegularOddHigh']} />
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                            <BetBackgroundDiv id={"Bet_RegularOddMedium"} type={"odd"} active={props.activeOdds.indexOf('RegularOddMedium') !== -1} disabled={props.disabledOdds.indexOf('RegularOddMedium') !== -1} style={{ borderRadius: '9px' }}>
                                <BetButtonDiv type={"odd"} onClick={() => props.activeOdds.indexOf('RegularOddMedium') === -1 && props.disabledOdds.indexOf('RegularOddMedium') === -1 && props.onBet && props.onBet('RegularOddMedium')} style={{ borderRadius: '7px' }}>
                                    <BetItemNameDiv style={{ fontSize: '17px' }}><div>홀중</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['RegularOddMedium']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['RegularOddMedium'] + props.winAmount['RegularOddMedium']} />
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                            <BetBackgroundDiv id={"Bet_RegularOddLow"} type={"odd"} active={props.activeOdds.indexOf('RegularOddLow') !== -1} disabled={props.disabledOdds.indexOf('RegularOddLow') !== -1} style={{ borderRadius: '9px' }}>
                                <BetButtonDiv type={"odd"} onClick={() => props.activeOdds.indexOf('RegularOddLow') === -1 && props.disabledOdds.indexOf('RegularOddLow') === -1 && props.onBet && props.onBet('RegularOddLow')} style={{ borderRadius: '7px' }}>
                                    <BetItemNameDiv style={{ fontSize: '17px' }}><div>홀소</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['RegularOddLow']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['RegularOddLow'] + props.winAmount['RegularOddLow']} />
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                            <BetBackgroundDiv id={"Bet_RegularEvenHigh"} type={"even"} active={props.activeOdds.indexOf('RegularEvenHigh') !== -1} disabled={props.disabledOdds.indexOf('RegularEvenHigh') !== -1} style={{ borderRadius: '9px' }}>
                                <BetButtonDiv type={"even"} onClick={() => props.activeOdds.indexOf('RegularEvenHigh') === -1 && props.disabledOdds.indexOf('RegularEvenHigh') === -1 && props.onBet && props.onBet('RegularEvenHigh')} style={{ borderRadius: '7px' }}>
                                    <BetItemNameDiv style={{ fontSize: '17px' }}><div>짝대</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['RegularEvenHigh']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['RegularEvenHigh'] + props.winAmount['RegularEvenHigh']} />
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                            <BetBackgroundDiv id={"Bet_RegularEvenMedium"} type={"even"} active={props.activeOdds.indexOf('RegularEvenMedium') !== -1} disabled={props.disabledOdds.indexOf('RegularEvenMedium') !== -1} style={{ borderRadius: '9px' }}>
                                <BetButtonDiv type={"even"} onClick={() => props.activeOdds.indexOf('RegularEvenMedium') === -1 && props.disabledOdds.indexOf('RegularEvenMedium') === -1 && props.onBet && props.onBet('RegularEvenMedium')} style={{ borderRadius: '7px' }}>
                                    <BetItemNameDiv style={{ fontSize: '17px' }}><div>짝중</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['RegularEvenMedium']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['RegularEvenMedium'] + props.winAmount['RegularEvenMedium']} />
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                            <BetBackgroundDiv id={"Bet_RegularEvenLow"} type={"even"} active={props.activeOdds.indexOf('RegularEvenLow') !== -1} disabled={props.disabledOdds.indexOf('RegularEvenLow') !== -1} style={{ borderRadius: '9px' }}>
                                <BetButtonDiv type={"even"} onClick={() => props.activeOdds.indexOf('RegularEvenLow') === -1 && props.disabledOdds.indexOf('RegularEvenLow') === -1 && props.onBet && props.onBet('RegularEvenLow')} style={{ borderRadius: '7px' }}>
                                    <BetItemNameDiv style={{ fontSize: '17px' }}><div>짝소</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['RegularEvenLow']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['RegularEvenLow'] + props.winAmount['RegularEvenLow']} />
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                        </LineDiv>
                    </BetPanelElement>

                    <BetPanelElement style={{ width: 'calc(43% - 3px)', height: 'calc(33% - 3px)', left: 0, top: 0 }} betHidden={props.marketType !== 'custom'}>
                        <BetPanelHeader>일반볼 대/중/소</BetPanelHeader>
                        <LineDiv>
                            <BetBackgroundDiv id={"Bet_RegularHigh"} active={props.activeOdds.indexOf('RegularHigh') !== -1} disabled={props.disabledOdds.indexOf('RegularHigh') !== -1} type={"size"} style={{ borderRadius: '9px' }}>
                                <BetButtonDiv type={"size"} onClick={() => props.activeOdds.indexOf('RegularHigh') === -1 && props.disabledOdds.indexOf('RegularHigh') === -1 && props.onBet && props.onBet('RegularHigh')} style={{ borderRadius: '7px' }}>
                                    <BetItemNameDiv style={{ fontSize: '17px' }}><div>대</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['RegularHigh']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['RegularHigh'] + props.winAmount['RegularHigh']} />
                                    <BetItemRangeDiv><div>81-130</div></BetItemRangeDiv>
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                            <BetBackgroundDiv id={"Bet_RegularMedium"} active={props.activeOdds.indexOf('RegularMedium') !== -1} disabled={props.disabledOdds.indexOf('RegularMedium') !== -1} type={"size"} style={{ borderRadius: '9px' }}>
                                <BetButtonDiv type={"size"} onClick={() => props.activeOdds.indexOf('RegularMedium') === -1 && props.disabledOdds.indexOf('RegularMedium') === -1 && props.onBet && props.onBet('RegularMedium')} style={{ borderRadius: '7px' }}>
                                    <BetItemNameDiv style={{ fontSize: '17px' }}><div>중</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['RegularMedium']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['RegularMedium'] + props.winAmount['RegularMedium']} />
                                    <BetItemRangeDiv><div>65-80</div></BetItemRangeDiv>
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                            <BetBackgroundDiv id={"Bet_RegularLow"} active={props.activeOdds.indexOf('RegularLow') !== -1} disabled={props.disabledOdds.indexOf('RegularLow') !== -1} type={"size"} style={{ borderRadius: '9px' }}>
                                <BetButtonDiv type={"size"} onClick={() => props.activeOdds.indexOf('RegularLow') === -1 && props.disabledOdds.indexOf('RegularLow') === -1 && props.onBet && props.onBet('RegularLow')} style={{ borderRadius: '7px' }}>
                                    <BetItemNameDiv style={{ fontSize: '17px' }}><div>소</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['RegularLow']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['RegularLow'] + props.winAmount['RegularLow']} />
                                    <BetItemRangeDiv><div>15-64</div></BetItemRangeDiv>
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                        </LineDiv>
                    </BetPanelElement>
                    <BetPanelElement style={{ width: 'calc(57% - 3px)', height: 'calc(33% - 3px)', left: 'calc(43% + 3px)', top: 0 }} betHidden={props.marketType !== 'custom'}>
                        <BetPanelHeader>일반볼+파워볼 조합 (홀짝)</BetPanelHeader>
                        <LineDiv>
                            <BetBackgroundDiv id={"Bet_RegularOddPowerOdd"} type={"odd"} active={props.activeOdds.indexOf('RegularOddPowerOdd') !== -1} disabled={props.disabledOdds.indexOf('RegularOddPowerOdd') !== -1} style={{ borderRadius: '9px' }}>
                                <BetButtonDiv type={"odd"} onClick={() => props.activeOdds.indexOf('RegularOddPowerOdd') === -1 && props.disabledOdds.indexOf('RegularOddPowerOdd') === -1 && props.onBet && props.onBet('RegularOddPowerOdd')} style={{ borderRadius: '7px' }}>
                                    <BetItemNameDiv style={{ fontSize: '17px' }}><div>홀P홀</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['RegularOddPowerOdd']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['RegularOddPowerOdd'] + props.winAmount['RegularOddPowerOdd']} />
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                            <BetBackgroundDiv id={"Bet_RegularOddPowerEven"} type={"odd"} active={props.activeOdds.indexOf('RegularOddPowerEven') !== -1} disabled={props.disabledOdds.indexOf('RegularOddPowerEven') !== -1} style={{ borderRadius: '9px' }}>
                                <BetButtonDiv type={"odd"} onClick={() => props.activeOdds.indexOf('RegularOddPowerEven') === -1 && props.disabledOdds.indexOf('RegularOddPowerEven') === -1 && props.onBet && props.onBet('RegularOddPowerEven')} style={{ borderRadius: '7px' }}>
                                    <BetItemNameDiv style={{ fontSize: '17px' }}><div>홀P짝</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['RegularOddPowerEven']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['RegularOddPowerEven'] + props.winAmount['RegularOddPowerEven']} />
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                            <BetBackgroundDiv id={"Bet_RegularEvenPowerOdd"} type={"even"} active={props.activeOdds.indexOf('RegularEvenPowerOdd') !== -1} disabled={props.disabledOdds.indexOf('RegularEvenPowerOdd') !== -1} style={{ borderRadius: '9px' }}>
                                <BetButtonDiv type={"even"} onClick={() => props.activeOdds.indexOf('RegularEvenPowerOdd') === -1 && props.disabledOdds.indexOf('RegularEvenPowerOdd') === -1 && props.onBet && props.onBet('RegularEvenPowerOdd')} style={{ borderRadius: '7px' }}>
                                    <BetItemNameDiv style={{ fontSize: '17px' }}><div>짝P홀</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['RegularEvenPowerOdd']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['RegularEvenPowerOdd'] + props.winAmount['RegularEvenPowerOdd']} />
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                            <BetBackgroundDiv id={"Bet_RegularEvenPowerEven"} type={"even"} active={props.activeOdds.indexOf('RegularEvenPowerEven') !== -1} disabled={props.disabledOdds.indexOf('RegularEvenPowerEven') !== -1} style={{ borderRadius: '9px' }}>
                                <BetButtonDiv type={"even"} onClick={() => props.activeOdds.indexOf('RegularEvenPowerEven') === -1 && props.disabledOdds.indexOf('RegularEvenPowerEven') === -1 && props.onBet && props.onBet('RegularEvenPowerEven')} style={{ borderRadius: '7px' }}>
                                    <BetItemNameDiv style={{ fontSize: '17px' }}><div>짝P짝</div></BetItemNameDiv>
                                    <BetItemOddsDiv>{props.odds['RegularEvenPowerEven']}x</BetItemOddsDiv>
                                    <BetChip amount={props.betAmount['RegularEvenPowerEven'] + props.winAmount['RegularEvenPowerEven']} />
                                </BetButtonDiv>
                            </BetBackgroundDiv>
                        </LineDiv>
                    </BetPanelElement>

                    <BetPanelElement style={{ width: '100%', height: 'calc(66% - 3px)', left: 0, top: 'calc(33% + 3px)' }} betHidden={props.marketType !== 'custom'}>
                        <BetPanelHeader>파워볼 정확한 숫자</BetPanelHeader>
                        <div style={{ display: 'flex', flexDirection: 'column', height: 'calc(100% - 23px)', gap: '5px' }}>
                            <LineDiv>
                                <BetBackgroundDiv id={"Bet_Powerball0"} type={"even"} active={props.activeOdds.indexOf('Powerball0') !== -1} disabled={props.disabledOdds.indexOf('Powerball0') !== -1} style={{ borderRadius: '9px' }}>
                                    <BetButtonDiv type={"even"} onClick={() => props.activeOdds.indexOf('Powerball0') === -1 && props.disabledOdds.indexOf('Powerball0') === -1 && props.onBet && props.onBet('Powerball0')} style={{ borderRadius: '7px' }}>
                                        <BetItemNameDiv><div>P0</div></BetItemNameDiv>
                                        <BetItemOddsDiv>{props.odds['Powerball0']}x</BetItemOddsDiv>
                                        <BetChip amount={props.betAmount['Powerball0'] + props.winAmount['Powerball0']} />
                                    </BetButtonDiv>
                                </BetBackgroundDiv>
                                <BetBackgroundDiv id={"Bet_Powerball1"} type={"odd"} active={props.activeOdds.indexOf('Powerball1') !== -1} disabled={props.disabledOdds.indexOf('Powerball1') !== -1} style={{ borderRadius: '9px' }}>
                                    <BetButtonDiv type={"odd"} onClick={() => props.activeOdds.indexOf('Powerball1') === -1 && props.disabledOdds.indexOf('Powerball1') === -1 && props.onBet && props.onBet('Powerball1')} style={{ borderRadius: '7px' }}>
                                        <BetItemNameDiv><div>P1</div></BetItemNameDiv>
                                        <BetItemOddsDiv>{props.odds['Powerball1']}x</BetItemOddsDiv>
                                        <BetChip amount={props.betAmount['Powerball1'] + props.winAmount['Powerball1']} />
                                    </BetButtonDiv>
                                </BetBackgroundDiv>
                                <BetBackgroundDiv id={"Bet_Powerball2"} type={"even"} active={props.activeOdds.indexOf('Powerball2') !== -1} disabled={props.disabledOdds.indexOf('Powerball2') !== -1} style={{ borderRadius: '9px' }}>
                                    <BetButtonDiv type={"even"} onClick={() => props.activeOdds.indexOf('Powerball2') === -1 && props.disabledOdds.indexOf('Powerball2') === -1 && props.onBet && props.onBet('Powerball2')} style={{ borderRadius: '7px' }}>
                                        <BetItemNameDiv><div>P2</div></BetItemNameDiv>
                                        <BetItemOddsDiv>{props.odds['Powerball2']}x</BetItemOddsDiv>
                                        <BetChip amount={props.betAmount['Powerball2'] + props.winAmount['Powerball2']} />
                                    </BetButtonDiv>
                                </BetBackgroundDiv>
                                <BetBackgroundDiv id={"Bet_Powerball3"} type={"odd"} active={props.activeOdds.indexOf('Powerball3') !== -1} disabled={props.disabledOdds.indexOf('Powerball3') !== -1} style={{ borderRadius: '9px' }}>
                                    <BetButtonDiv type={"odd"} onClick={() => props.activeOdds.indexOf('Powerball3') === -1 && props.disabledOdds.indexOf('Powerball3') === -1 && props.onBet && props.onBet('Powerball3')} style={{ borderRadius: '7px' }}>
                                        <BetItemNameDiv><div>P3</div></BetItemNameDiv>
                                        <BetItemOddsDiv>{props.odds['Powerball3']}x</BetItemOddsDiv>
                                        <BetChip amount={props.betAmount['Powerball3'] + props.winAmount['Powerball3']} />
                                    </BetButtonDiv>
                                </BetBackgroundDiv>
                                <BetBackgroundDiv id={"Bet_Powerball4"} type={"even"} active={props.activeOdds.indexOf('Powerball4') !== -1} disabled={props.disabledOdds.indexOf('Powerball4') !== -1} style={{ borderRadius: '9px' }}>
                                    <BetButtonDiv type={"even"} onClick={() => props.activeOdds.indexOf('Powerball4') === -1 && props.disabledOdds.indexOf('Powerball4') === -1 && props.onBet && props.onBet('Powerball4')} style={{ borderRadius: '7px' }}>
                                        <BetItemNameDiv><div>P4</div></BetItemNameDiv>
                                        <BetItemOddsDiv>{props.odds['Powerball4']}x</BetItemOddsDiv>
                                        <BetChip amount={props.betAmount['Powerball4'] + props.winAmount['Powerball4']} />
                                    </BetButtonDiv>
                                </BetBackgroundDiv>
                            </LineDiv>
                            <LineDiv>
                                <BetBackgroundDiv id={"Bet_Powerball5"} type={"odd"} active={props.activeOdds.indexOf('Powerball5') !== -1} disabled={props.disabledOdds.indexOf('Powerball5') !== -1} style={{ borderRadius: '9px' }}>
                                    <BetButtonDiv type={"odd"} onClick={() => props.activeOdds.indexOf('Powerball5') === -1 && props.disabledOdds.indexOf('Powerball5') === -1 && props.onBet && props.onBet('Powerball5')} style={{ borderRadius: '7px' }}>
                                        <BetItemNameDiv><div>P5</div></BetItemNameDiv>
                                        <BetItemOddsDiv>{props.odds['Powerball5']}x</BetItemOddsDiv>
                                        <BetChip amount={props.betAmount['Powerball5'] + props.winAmount['Powerball5']} />
                                    </BetButtonDiv>
                                </BetBackgroundDiv>
                                <BetBackgroundDiv id={"Bet_Powerball6"} type={"even"} active={props.activeOdds.indexOf('Powerball6') !== -1} disabled={props.disabledOdds.indexOf('Powerball6') !== -1} style={{ borderRadius: '9px' }}>
                                    <BetButtonDiv type={"even"} onClick={() => props.activeOdds.indexOf('Powerball6') === -1 && props.disabledOdds.indexOf('Powerball6') === -1 && props.onBet && props.onBet('Powerball6')} style={{ borderRadius: '7px' }}>
                                        <BetItemNameDiv><div>P6</div></BetItemNameDiv>
                                        <BetItemOddsDiv>{props.odds['Powerball6']}x</BetItemOddsDiv>
                                        <BetChip amount={props.betAmount['Powerball6'] + props.winAmount['Powerball6']} />
                                    </BetButtonDiv>
                                </BetBackgroundDiv>
                                <BetBackgroundDiv id={"Bet_Powerball7"} type={"odd"} active={props.activeOdds.indexOf('Powerball7') !== -1} disabled={props.disabledOdds.indexOf('Powerball7') !== -1} style={{ borderRadius: '9px' }}>
                                    <BetButtonDiv type={"odd"} onClick={() => props.activeOdds.indexOf('Powerball7') === -1 && props.disabledOdds.indexOf('Powerball7') === -1 && props.onBet && props.onBet('Powerball7')} style={{ borderRadius: '7px' }}>
                                        <BetItemNameDiv><div>P7</div></BetItemNameDiv>
                                        <BetItemOddsDiv>{props.odds['Powerball7']}x</BetItemOddsDiv>
                                        <BetChip amount={props.betAmount['Powerball7'] + props.winAmount['Powerball7']} />
                                    </BetButtonDiv>
                                </BetBackgroundDiv>
                                <BetBackgroundDiv id={"Bet_Powerball8"} type={"even"} active={props.activeOdds.indexOf('Powerball8') !== -1} disabled={props.disabledOdds.indexOf('Powerball8') !== -1} style={{ borderRadius: '9px' }}>
                                    <BetButtonDiv type={"even"} onClick={() => props.activeOdds.indexOf('Powerball8') === -1 && props.disabledOdds.indexOf('Powerball8') === -1 && props.onBet && props.onBet('Powerball8')} style={{ borderRadius: '7px' }}>
                                        <BetItemNameDiv><div>P8</div></BetItemNameDiv>
                                        <BetItemOddsDiv>{props.odds['Powerball8']}x</BetItemOddsDiv>
                                        <BetChip amount={props.betAmount['Powerball8'] + props.winAmount['Powerball8']} />
                                    </BetButtonDiv>
                                </BetBackgroundDiv>
                                <BetBackgroundDiv id={"Bet_Powerball9"} type={"odd"} active={props.activeOdds.indexOf('Powerball9') !== -1} disabled={props.disabledOdds.indexOf('Powerball9') !== -1} style={{ borderRadius: '9px' }}>
                                    <BetButtonDiv type={"odd"} onClick={() => props.activeOdds.indexOf('Powerball9') === -1 && props.disabledOdds.indexOf('Powerball9') === -1 && props.onBet && props.onBet('Powerball9')} style={{ borderRadius: '7px' }}>
                                        <BetItemNameDiv><div>P9</div></BetItemNameDiv>
                                        <BetItemOddsDiv>{props.odds['Powerball9']}x</BetItemOddsDiv>
                                        <BetChip amount={props.betAmount['Powerball9'] + props.winAmount['Powerball9']} />
                                    </BetButtonDiv>
                                </BetBackgroundDiv>
                            </LineDiv>
                        </div>
                    </BetPanelElement>
                </BetWrapper>
            </MobileView>
        </>
    );
}