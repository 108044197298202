import {FunctionComponent} from "react";
import styled from "styled-components";

const BetChipSvg = styled.svg<{ withRange?: boolean, hidden: boolean, originalChip?: boolean }>`
  position: absolute;
  width: 32px;
  ${props => props.originalChip !== true ? `
    margin-left: -16px;
    margin-top: -18px;  
  ` : `
    margin-left: 0;
    margin-top: 0;
  `}

  ${props => props.withRange ? `
  margin-top: 26px;
  ` : ''}
  
  ${props => props.hidden ? `
    opacity: 0;
    transition-delay: 0;
  ` : `
    opacity: 1;
    transition-delay: 500ms;
  `}
`;

export const BetChip: FunctionComponent<{ amount: number, withRange?: boolean, originalChip?: boolean }> = (props) => {
    let color = "rgb(89, 89, 89)";
    if (props.amount >= 10000000) {
        color = "rgb(222, 117, 113)";
    } else if (props.amount >= 5000000) {
        color = "rgb(0, 102, 153)";
    } else if (props.amount >= 1000000) {
        color = "rgb(222, 152, 7)";
    } else if (props.amount >= 500000) {
        color = "rgb(133, 72, 176)";
    } else if (props.amount >= 100000) {
        color = "rgb(26, 26, 26)";
    } else if (props.amount >= 25000) {
        color = "rgb(5, 174, 41)";
    } else if (props.amount >= 5000) {
        color = "rgb(206, 29, 0)";
    }

    let amount = `${props.amount}`;
    if (props.amount >= 1000000) {
        if (props.amount % 100000 === 0) {
            amount = `${props.amount / 1000000}M`;
        } else {
            amount = `${(Math.floor(props.amount / 100000) / 10).toFixed(1)}M+`;
        }
    } else if (props.amount >= 10000) {
        amount = `${props.amount / 1000}K`;
    }

    return (
        <BetChipSvg hidden={props.amount === 0} withRange={props.withRange} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 78 78" className="graphics--317bd" style={{color,}} originalChip={props.originalChip}>
            <g>
                <circle className="paint--844e8" cx="39.019" cy="38.999" r="38.5" fill="currentColor"></circle>
                <path className="body--7d1ff" fill="#ebebeb"
                      d="M38.94 12.5A26.5 26.5 0 1 0 65.44 39a26.529 26.529 0 0 0-26.5-26.5zm0 52A25.5 25.5 0 1 1 64.439 39 25.53 25.53 0 0 1 38.94 64.5z"></path>
                <circle className="text-background--9cda6" cx="39" cy="38.997" r="25.5" fill="#000"
                        opacity="0.2"></circle>
                <path className="body--7d1ff" fill="#ebebeb"
                      d="M38.941 0a39 39 0 1 0 39 39 39.046 39.046 0 0 0-39-39zm-2.088 76.439l.483-8.471a28.99 28.99 0 0 1-4.668-.639l-1.783 8.291a37.277 37.277 0 0 1-12.144-5.051l4.6-7.124a29.143 29.143 0 0 1-8.85-8.851l-7.124 4.6a37.28 37.28 0 0 1-5.045-12.13l8.3-1.784a28.99 28.99 0 0 1-.639-4.668l-8.483.482C1.463 40.4 1.44 39.7 1.44 39s.023-1.391.061-2.08l8.478.483a28.99 28.99 0 0 1 .639-4.668l-8.3-1.785a37.275 37.275 0 0 1 5.047-12.142l7.126 4.6a29.143 29.143 0 0 1 8.85-8.851l-4.6-7.125a37.28 37.28 0 0 1 12.142-5.05l1.786 8.3a28.99 28.99 0 0 1 4.668-.639l-.483-8.484c.692-.038 1.388-.061 2.089-.061s1.4.023 2.087.061l-.483 8.484a28.99 28.99 0 0 1 4.668.639L47 2.381a37.276 37.276 0 0 1 12.14 5.05l-4.6 7.126a29.14 29.14 0 0 1 8.849 8.85l7.127-4.6a37.276 37.276 0 0 1 5.044 12.142l-8.3 1.785a28.99 28.99 0 0 1 .64 4.666l8.478-.483c.038.689.061 1.382.061 2.08s-.023 1.4-.062 2.1l-8.477-.486a28.99 28.99 0 0 1-.639 4.668l8.3 1.784a37.282 37.282 0 0 1-5.046 12.132l-7.125-4.6a29.14 29.14 0 0 1-8.849 8.85l4.6 7.125A37.275 37.275 0 0 1 47 75.619l-1.783-8.291a28.99 28.99 0 0 1-4.668.639l.483 8.471c-.691.038-1.386.061-2.087.061s-1.401-.022-2.092-.06z"></path>
            </g>
            <text className="value--22fcf" x="50%" y="50%" fontSize="18" dy="6" fill="#fff" fontWeight="600"
                  textAnchor="middle">{amount}</text>
        </BetChipSvg>
    );
}